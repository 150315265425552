import { getBuffer, useBuffer } from "./stores/useAppStore";
import { updateSelection, useUiStore } from "./stores/useUiStore";

const baseOffsets = {
  w: {
    left: -10,
  },
  s: {
    bottom: -10,
  },
  e: {
    right: -10,
  },
  n: {
    top: -10,
  },
};

const offsets = {
  ...baseOffsets,
  nw: {
    ...baseOffsets.n,
    ...baseOffsets.w,
  },
  ne: {
    ...baseOffsets.n,
    ...baseOffsets.e,
  },
  se: {
    ...baseOffsets.s,
    ...baseOffsets.e,
  },
  sw: {
    ...baseOffsets.s,
    ...baseOffsets.w,
  },
};

const baseSizing = {
  w: {
    height: "100%",
    width: 20,
  },
  n: {
    height: 20,
    width: "100%",
  },
};

export const sizes = {
  ...baseSizing,
  e: baseSizing.w,
  s: baseSizing.n,
};

export const cursors = {
  nw: "nwse-resize",
  ne: "nesw-resize",
  se: "nwse-resize",
  sw: "nesw-resize",
  w: "ew-resize",
  e: "ew-resize",
  n: "ns-resize",
  s: "ns-resize",
};

const ResizeHandle = ({ dir }) => {
  return (
    <>
      <div
        className="absolute flex justify-center items-center z-1"
        data-dir={dir}
        data-resize={true}
        style={{
          width: 20,
          height: 20,
          cursor: cursors[dir],
          ...offsets[dir]
        }}
      >
        <div
          className="border-2 border-blue-400 bg-white"
          data-dir={dir}
          data-resize={true}
          style={{
            width: 8,
            height: 8,
          }}
        />
      </div>
    </>
  );
};

const ResizeSideHandle = ({ dir }) => {
  return (
    <div
      className="absolute flex justify-center items-center"
      data-dir={dir}
      data-resize={true}
      style={{
        cursor: cursors[dir],
        ...sizes[dir],
        ...offsets[dir]
      }}
    />
  );
};

// Function to calculate the selection box style
const getSelectionBoxStyle = (s) => {
  return {
    left: `${s.left}px`,
    top: `${s.top}px`,
    width: `${s.width}px`,
    height: `${s.height}px`,
  };
};

/**
 * Replaces the left and top to 0, used atm when we want to use the bounds as the size of a canvas
 * Doesn't have to be bounds, can work with anything containing a width and height element
 */
const boundsToFill = (bounds) => {
  return {
    left: 0,
    top: 0,
    width: bounds?.width || 0,
    height: bounds?.height || 0,
  };
};

const boundsToSelection = (bounds) => {
  if (!bounds) {
    return { x1: 0, y1: 0, x2: 0, y2: 0 };
  }

  return {
    x1: bounds.left,
    y1: bounds.top,
    x2: bounds.left + bounds.width,
    y2: bounds.top + bounds.height,
  };
};

function updateSelectionBox(x1, y1, x2, y2, mouseX, mouseY, dir) {
  // Calculate the distance from the corner to the mouse position
  const deltaX = Math.abs(mouseX - (dir.includes("w") ? x2 : x1));
  const deltaY = Math.abs(mouseY - (dir.includes("n") ? y2 : y1));
  const minLength = 40;

  // Determine the side length of the new square based on the greater change
  // this default will be used for corners, otherwise we'll adjust as needed
  let sideLength = Math.max(deltaX, deltaY);
  if (["w", "e"].includes(dir)) {
    sideLength = deltaX;
  }
  if (["n", "s"].includes(dir)) {
    sideLength = deltaY;
  }

  sideLength = Math.max(sideLength, minLength);

  // Adjust the coordinates based on which corner is being dragged
  switch (dir) {
    // so if you're adjusting hte top right
    // we wanna leave x1 and y2 alone?
    case "w":
      x1 = x2 - sideLength;
      break;
    case "e":
      x2 = x1 + sideLength;
      break;
    case "n":
      y1 = y2 - sideLength;
      break;
    case "s":
      y2 = y1 + sideLength;
      break;
    case "nw": {
      x1 = x2 - sideLength;
      y1 = y2 - sideLength;
      break;
    }
    case "ne": {
      x2 = x1 + sideLength;
      y1 = y2 - sideLength;
      break;
    }
    case "sw": {
      x1 = x2 - sideLength;
      y2 = y1 + sideLength;
      break;
    }
    case "se": {
      x2 = x1 + sideLength;
      y2 = y1 + sideLength;
      break;
    }
    default:
      // Handle default case or error
      break;
  }

  y2 = y1 + sideLength;
  x2 = x1 + sideLength;

  // return selection;
  return { x1, y1, x2, y2 };
}

const getResize = (resizing, x, y) => {
  const s = resizing.initial;
  return updateSelectionBox(s.x1, s.y1, s.x2, s.y2, x, y, resizing.direction);
};

const handleResize = (pos, buffer, resizing) => {
  const x = pos.x - buffer;
  const y = pos.y - buffer;

  const points = getResize(resizing, x, y);
  updateSelection(points);
};

const selectionToBounds = (selection) => {
  if (!selection) {
    return { left: 0, top: 0, width: 0, height: 0 };
  }

  let width = Math.abs(selection.x1 - selection.x2);
  let height = Math.abs(selection.y1 - selection.y2);

  return {
    left: selection.x1,
    top: selection.y1,
    width,
    height,
  };
};

const getSelection = () => {
  return useUiStore.getState().selection;
};

const selectionToBoundsWithBuffer = (s) => {
  const buffer = getBuffer();

  const bounds = selectionToBounds(s);

  return {
    ...bounds,
    left: bounds.left + buffer,
    top: bounds.top + buffer,
  };
};

const getSelectionWithBuffer = () => {
  const s = getSelection();

  return selectionToBoundsWithBuffer(s);
};

const useSelection = () => {
  const buffer = useBuffer();
  const selection = useUiStore(s => s.selection);

  return getSelectionWithBuffer(selection, buffer);
};

const SelectionBox = ({ isResizing }) => {
  const selection = useSelection();

  if (!selection?.width || !selection?.height) {
    return null;
  }

  return (
    <div
      className={`absolute bg-gray-800 bg-opacity-20 border ${isResizing ? "border-gray-400" : "border-blue-400"}`}
      style={getSelectionBoxStyle(selection)}
    >
      {!isResizing ? (
        <>
          {["w", "n", "e", "s"].map((dir) => (
            <ResizeSideHandle dir={dir} />
          ))}
          {["nw", "ne", "se", "sw"].map((dir) => (
            <ResizeHandle dir={dir} />
          ))}
        </>
      ) : null}
    </div>
  );
};

export {
  boundsToFill,
  boundsToSelection,
  SelectionBox,
  useSelection,
  getSelection,
  getSelectionWithBuffer,
  selectionToBoundsWithBuffer,
  selectionToBounds,
  handleResize,
};