import { create, useStore } from 'zustand';
import { temporal } from 'zundo';
import { persist } from 'zustand/middleware';
import { partialize } from './util';
import { emojiToLottie } from '../gif';
import { useUiStore } from './useUiStore';
import { MAX_CANVAS_HEIGHT, MAX_CANVAS_WIDTH } from '../sizes';

const initialSticker = {
  bounds: { left: 0, top: 0, width: 0, height: 0, },
  img: null,
  transform: {
    rotation: 0,
  },
};

const resetState = {
  isPlaying: false,
  isRecording: false,
  status: null,
  frame: 0,
  timeline: [],
  size: { width: MAX_CANVAS_WIDTH, height: MAX_CANVAS_HEIGHT },
  canvasSize: null,
  draggingSticker: null,
  aspectRatio: null,
  baseCanvas: null,
  isPaywallOpen: false,
  isAuthModalOpen: false,
  exportFormat: "webm",
};

export const initialState = {
  ...resetState,
  images: {},
  animations: {},
  layers: {},
  buffer: 100,
  gradient: "g6",
  radius: 15,
  shadow: 45,
  mode: "image",
  session: null,
};

// fields to track
const whitelistPersistent = ["buffer", "gradient", "radius", "shadow", "timeline", "layers", "mode", "aspectRatio", "session"];
const whitelistTemporal = ["buffer", "gradient", "radius", "shadow", "layers", "timeline", "aspectRatio"];

const useAppStore = create(
  persist(
  temporal(
    (set, get) => ({
      ...initialState,
      setMode: (val) => set({ mode: val }),
      setImages: (val) => set({ images: val }),
      addImage: (id, val) => {
        // add the image to the list
        const updates = {
          ...get().images,
          [id]: val,
        };

        set({ images: updates });
      },
      setAnimation: (id, val) => {
        set({
          animations: {
            ...get().animations,
            [id]: val,
          }, 
        })
      },
      addTimelineLayer: (layer) => {
        // add the layer to the timeline for playback
        const timeline = [...get().timeline, layer];

        set({ timeline });
      },
      addLayer: (id, val, layer) => {
        const freshStart = ["stripe", "base"].includes(layer.type);

        // add the layer to the layers object for quick retrieval
        let layers = freshStart ? {} : get().layers;
        let timeline = freshStart ? [] : get().timeline;

        // add the layer to the timeline for playback
        timeline = [...timeline, layer];

        layers = {
          ...layers,
          [id]: val,
        };

        let state = freshStart ? resetState : {};

        // doing this together in 1 call for improved undo handling
        set({ ...state,  layers, timeline });
      },
      removeLayer: (id) => {
        const layers = { ...get().layers };

        if (layers[id]) {
          delete layers[id];
          const timeline = get().timeline.filter((l) => l.id !== id);

          return set({
            layers,
            timeline,
          });
        }
      },
      updateLayer: (id, updates) => {
        if (!get().layers[id]) {
          return;
        }

        const layers = {
          ...get().layers,
          [id]: {
            ...get().layers[id],
            ...updates,
          },
        };

        return set({ layers });
      },
      setGradient: (val) => set({ gradient: val }),
      setAspectRatio: (val) => set({ aspectRatio: val }),
      setBuffer: (val) => set({ buffer: val }),
      setRadius: (val) => set({ radius: val }),
      setShadow: (val) => set({ shadow: val }),
      reset: () => set({ frame: 0, isPlaying: false }),
      restart: () => set({ frame: 0, isPlaying: true }),
      setIsPlaying: (isPlaying) => set({ isPlaying }),
      setIsRecording: (isRecording) => set({ isRecording }),
      setStatus: (status) => set({ status }),
      incFrame: () => set({ frame: get().frame + 1 }),
      setSize: (size) => set({ size }),
      setIsPaywallOpen: (isPaywallOpen) => set({ isPaywallOpen }),
      setIsAuthModalOpen: (isAuthModalOpen) => set({ isAuthModalOpen }),
      setExportFormat: (exportFormat) => set({ exportFormat }),
      resetStore: () => set({ ...resetState }),
    }),
    {
      partialize: partialize.whitelist(whitelistTemporal),
      equality: (p, s) => {
        // check all the keys
        return whitelistTemporal.every((key) => p[key] === s[key]);
      },
    }),
    {
      name: 'snapmate::store', // name of the item in the storage (must be unique)
      partialize: partialize.whitelist(whitelistPersistent),
      // skipHydration: true,
    },
  ),
);

const useTemporalStore = (selector, equality) => useStore(useAppStore.temporal, selector, equality);

// get current state
const getState = (s) => s || useAppStore.getState();

const getUpdateLayersObj = (id, updates) => {
  const layers = useAppStore.getState().layers;
  if (!layers[id]) {
    return layers;
  }

  return {
    ...layers,
    [id]: {
      ...layers[id],
      ...updates,
    },
  };
};

const getLayers = (s) => {
  const state = getState(s);
  return Object.values(state.layers);
};

const isInTimeline = (id) => {
  // make sure the layer exists in the timeline
  const ids = useAppStore.getState().timeline.map((tl) => tl.id);
  return ids.includes(id);
};

const getLayerTypes = (types = [], s) => {
  // allow passing in state so it can be used in a subscribe fn
  return getLayers(s)
    .filter((value) => types.includes(value.type))
    .filter((layer) => isInTimeline(layer.id));
};

const getStickers = (state) => getLayerTypes("sticker", state);
const getCounters = (state) => getLayerTypes("counter", state);
const getStripeLayers = (state) => getLayerTypes("stripe", state);
const getBaseLayers = (state) => getLayerTypes("base", state);
const getLines = (state) => getLayerTypes("line", state);
const getLineGraphs = (state) => getLayerTypes("linegraph", state);

const getLayer = (id) => {
  return useAppStore.getState().layers[id];
};

const getActiveSticker = () => {
  const activeId = useUiStore.getState().activeId;
  return getLayer(activeId);
};

const getImages = (state) => {
  const stickers = getStickers(state);
  const stripe = getStripeLayers(state);
  const base = getBaseLayers(state);

  return [
    ...stickers.map((s) => s.img),
    ...stripe.map((s) => s.img),
    ...base.map((s) => s.img),
  ].filter((s) => s);
};

const getImageStrings = (state) => {
  const images = getImages(state);
  const strs = images.map((image) => image.id).filter((s) => s);
  return strs;
};

const getAnimations = (state) => {
  const stickers = getStickers(state);
  const code = getState(state).draggingSticker;
  const draggingUrl = code ? emojiToLottie(code) : null;

  return [...stickers.map((sticker) => sticker.lottieUrl), draggingUrl].filter((s) => s);
};

const getAnimation = (url) => {
  return useAppStore.getState().animations[url] || null;
};

const getAnimationInstances = (state) => {
  const animations = getAnimations(state);
  return animations.map((anim) => getAnimation(anim)).filter((s) => s);
};

const getAnimationLayers = (state) => {
  const stickers = getStickers(state);
  return stickers.map((sticker) => {
    const animation = getAnimation(sticker.lottieUrl);
    return {
      layer: sticker,
      animation,
    };
  }).filter((s) => s);
};

const sortTimeline = (timeline) => {
  return [...timeline].sort((a, b) => {
    if (a.type === "base") {
      return -1;
    }
    if (a.type === "stripe") {
      return -1;
    }
    if (a.type === "sticker") {
      return 1;
    }
    if (b.type === "base") {
      return 1;
    }
    if (b.type === "stripe") {
      return 1;
    }
    if (b.type === "sticker") {
      return -1;
    }
    return 0;
  });
};

const isImage = (i) => {
  return i instanceof HTMLImageElement;
}

const isCanvas = (i) => {
  return i instanceof HTMLCanvasElement;
}

const getImage = (id) => {
  return useAppStore.getState().images[id] || null;
};

const getUserImg = () => {
  const base = getBaseLayers(useAppStore.getState())[0];
  return getImage(base?.img?.id);
};

const getStripeImage = () => {
  const stripe = getStripeLayers(useAppStore.getState())[0];
  return getImage(stripe?.img?.id);
};

const reset = () => {
  useUiStore.getState().resetStore();
  useAppStore.getState().resetStore();
  useAppStore.temporal.getState().clear();
};

const getBuffer = () => {
  const tempBuffer = useUiStore.getState().tempBuffer;
  const buffer = useAppStore.getState().buffer;
  return tempBuffer ?? buffer;
};

const useBuffer = () => {
  const tempBuffer = useUiStore((s) => s.tempBuffer);
  const buffer = useAppStore((s) => s.buffer);
  return tempBuffer ?? buffer;
};

const isPlaying = (playing, mode) => {
  return playing && mode === "video";
};

const getIsPlaying = () => {
  return isPlaying(useAppStore.getState().isPlaying, useAppStore.getState().mode);
};

const useIsPlaying = () => {
  const playing = useAppStore(s => s.isPlaying);
  const mode = useAppStore(s => s.mode);

  return isPlaying(playing, mode);
};

const getIsAuthenticated = () => {
  return !!useAppStore.getState().session
};

const getIsSubscribed = (session) => {
  return !!session?.user?.user_metadata?.is_active;
};

const useIsSubscribed = () => {
  const session = useAppStore(s => s.session);
  return getIsSubscribed(session);
}

const getIsPaywallVisible = () => {
  const curr = useAppStore.getState();

  return curr.mode === "video" && !getIsSubscribed(curr.session);
}

const getIsEmojiSuggestionVisible = () => {
  const curr = useAppStore.getState();
  return curr.mode === "video" && !getIsSubscribed(curr.session);
}

const getIsWatermarkVisible = () => {
  const curr = useAppStore.getState();
  return curr.timeline?.length && !getIsSubscribed(curr.session);
}

export {
  useAppStore,
  isImage,
  isCanvas,
  getUserImg,
  getImage,
  getImages,
  getImageStrings,
  getAnimation,
  getAnimationLayers,
  getAnimationInstances,
  getAnimations,
  sortTimeline,
  getStickers,
  getCounters,
  getLayer,
  getLayers,
  getLineGraphs,
  getLines,
  getLayerTypes,
  getIsSubscribed,
  useTemporalStore,
  getUpdateLayersObj,
  initialSticker,
  getBaseLayers,
  getStripeLayers,
  getStripeImage,
  getActiveSticker,
  reset,
  useBuffer,
  getBuffer,
  getIsPlaying,
  useIsPlaying,
  getIsPaywallVisible,
  getIsWatermarkVisible,
  getIsEmojiSuggestionVisible,
  getIsAuthenticated,
  useIsSubscribed,
};
