import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { drawCenteredRoundedImage, scaleCanvas } from './draw';
import { fileToBase64 } from './base64';
import {
  getActiveSticker,
  getAnimationLayers,
  getLayer,
  getStickers,
  getStripeLayers,
  getUserImg,
  sortTimeline,
  useAppStore,
  useTemporalStore,
  getBuffer,
  initialState,
  useIsPlaying,
  getIsPaywallVisible,
  getIsWatermarkVisible,
  getIsPlaying,
  reset,
  getStripeImage,
  getIsAuthenticated,
} from './stores/useAppStore';
import { PlayButton } from './Timeline';
import { SelectionBox, boundsToSelection, cursors, handleResize, selectionToBounds } from './ResizeElement';
import { preloadImage } from './usePreloadMedia';
import { addBaseImg, addEmoji, addStripeLayer, updateSticker } from './stores/layers';
import { config } from "./config";
import { useUndo } from './useUndo';
import { EmojiPicker } from './EmojiPicker';
import useInterval from './hooks/useInterval';
import layerRenderer from "./layers";
import { capImageSize, cleanUrl, getCtx, getDuration, getFrameLength, getRecordingLength, getWrapperSize, onGetCanvasSize, onGetSize } from './util';
import { getSize } from './layers/stripe';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import ArrowUturnLeftIcon from '@heroicons/react/24/solid/ArrowUturnLeftIcon';
import { Toggle } from './Toggle';
import { CanvasOptions } from './CanvasOptions';
import { NewOptions } from './NewOptions';
import { useBackspace, useSpaceBar } from './hooks/useKeyPress';
import { useUiStore } from './stores/useUiStore';
import { EmptyCanvasOptions } from './EmptyCanvasOptions';
import { nanoid } from 'nanoid';
import { GradientOptions } from './GradientOptions';
import { ExportButton } from './ExportButton';
import { AuthModal } from './AuthModal';
import { UserButton } from './UserButton';
import { PaywallModal } from './PaywallModal';
import supabase from './supabase/client';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { EmojiSuggestionModal } from "./EmojiSuggestionModal";
import { getData } from './extension';
import { transcode, useRecorder } from './recording';
import { chunksToFormattedBlob, downloadImage, downloadVideoFromStorage } from './export';
import { Toaster, toast } from "react-hot-toast"

const fps = config.fps;
const maxHeight = 800;
const maxWidth = 800;

const getPosition = (e) => {
  const div = document.getElementById("wrapper");
  var rect = div.getBoundingClientRect();
  return { x: e.clientX - rect.left, y: e.clientY - rect.top };
};

const getPositionWithoutBuffer = (e) => {
  const buffer = getBuffer();
  const pos = getPosition(e);

  return {
    x: pos.x - buffer,
    y: pos.y - buffer,
  };
};

function App () {
  const isDraggingEmoji = useRef(null);

  const activeSticker = getActiveSticker();

  const mode = useAppStore((state) => state.mode);

  const setMode = useAppStore((state) => state.setMode);
  const removeLayer = useAppStore(s => s.removeLayer);

  // timeline store
  const setIsPlaying = useAppStore(s => s.setIsPlaying);
  const isPlaying = useIsPlaying();

  const setIsRecording = useAppStore(s => s.setIsRecording);
  const setExportFormat = useAppStore(s => s.setExportFormat);
  const setStatus = useAppStore(s => s.setStatus);
  const isRecording = useAppStore(s => s.isRecording);
  const resetTimeline = useAppStore(s => s.reset);
  const restartTimeline = useAppStore(s => s.restart);
  const incFrame = useAppStore(s => s.incFrame);
  const timeline = useAppStore((s) => s.timeline);
  const isPaywallOpen = useAppStore((s) => s.isPaywallOpen);
  const isAuthModalOpen = useAppStore((s) => s.isAuthModalOpen);

  const undo = useTemporalStore((state) => state.undo);

  const setTranslation = useUiStore(s => s.setTranslation);
  const resetTranslation = useUiStore(s => s.resetTranslation);
  const setResizing = useUiStore(s => s.setResizing);
  const resetResizing = useUiStore(s => s.resetResizing);
  const resizing = useUiStore(s => s.resizing);
  const setSelection = useUiStore(s => s.setSelection);
  const resetSelection = useUiStore(s => s.resetSelection);
  const isDraggingSticker = useUiStore(s => s.isDraggingSticker);
  const setIsDraggingSticker = useUiStore(s => s.setIsDraggingSticker);
  const setIsOverSticker = useUiStore(s => s.setIsOverSticker);
  const activeId = useUiStore(s => s.activeId);
  const setActiveId = useUiStore(s => s.setActiveId);
  const setIsPaywallOpen = useAppStore(s => s.setIsPaywallOpen);
  const setIsAuthModalOpen = useAppStore(s => s.setIsAuthModalOpen);

  const recorder = useRecorder();

  const isAuthModalVisible = !getIsAuthenticated();
  const isPaywallVisible = getIsPaywallVisible();

  const initData = async (key) => {
    const data = await getData(key);

    if (data) {
      reset();

      addStripeLayer(data);

      // set the snap type and mode back to video
      useAppStore.setState({ mode: "video" });

      onPreview();
    }
  };

  const initSession = async () => {
    const { data, error } = await supabase.auth.refreshSession();
    if (data?.session) {
      useAppStore.setState({ session: data.session });
    }
  };

  const init = async () => {
    const url = new URL(document.location);
    const key = url.searchParams.get("key");

    const promises = [];
    promises.push(initSession());

    if (key) {
      promises.push(initData(key));
    }

    await Promise.all(promises);

    // only clean the url once these functions above have been able to use the url if necessary
    cleanUrl();
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    // we should probably group these
    onSetupBaseCanvas();

    // initial trigger
    onDraw();

    // reset the paywall if it was open
    setIsPaywallOpen(false);

    if (mode === "video") {
      resetTimeline();
    } else {
      // turn off the player
      setIsPlaying(false);
    }
  }, [mode]);

  const onDrop = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const pos = getPositionWithoutBuffer(ev);

    var data = ev.dataTransfer.getData("text");

    if (data) {
      const code = data.split("-")[1];
      addEmoji(code, pos);
      // trigger the preview
      onPreview();
    }
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    const getCursor = (s) => {
      if (s.resizing.active) {
        return s.resizing.cursor;
      }
      if (s.isDraggingSticker) {
        return "grabbing";
      }
      if (s.isOverSticker) {
        return "grab";
      }

      return "default";
    };

    const handleCursor = (s) => {
      document.body.style.cursor = getCursor(s);
    };

    // Listening to selected changes, in this case when "paw" changes
    const unsub = useUiStore.subscribe((s, p) => {
      handleCursor(s);

      const baseFields = ["tempBuffer"];
      if (baseFields.some((field) => s[field] !== p[field])) {
        onSetupBaseCanvas()
      }

      const fields = ["translation", "selection"];
      // check if any of the values differ
      if (fields.some((field) => s[field] !== p[field])) {
        onDraw(); // this should acutally update this, including the counters within them
      }
    });

    return unsub;
  }, []);

  const isInSticker = (pos) => {
    const stickers = getStickers();
    const buf = getBuffer();

    const x = pos.x - buf;
    const y = pos.y - buf;

    // find the active sticker we're working with
    // put the list into reverse to the higher layers get priority
    return [...stickers].reverse().find((sticker) => {
      const s = sticker.bounds;
      const left = s.left;
      const top = s.top;
      const w = s.width;
      const h = s.height;
  
      return (s && x >= left && x <= left + w && y >= top && y <= top + h);
    });
  };

  // Event handlers for mouse events
  const handleMouseDown = useCallback((e) => {
    if (isPlaying) {
      return;
    }

    const pos = getPosition(e);

    const direction = e.target?.dataset?.dir;
    const isResize = e.target?.dataset?.resize;

    if (isResize) {
      setResizing({
        direction,
        cursor: cursors[direction],
        active: true,
        initial: useUiStore.getState().selection,
      });
      return;
    }

    const active = isInSticker(pos);
    // if we found an active sticker to be dragging
    if (active) {
      setIsDraggingSticker(true);

      setTranslation({
        x1: pos.x,
        y1: pos.y,
        x2: pos.x,
        y2: pos.y,
        initBounds: active.bounds,
        bounds: active.bounds,
        id: active.id,
      });

      setActiveId(active.id);
      return;
    }
  }, [isPlaying]);

  const syncSelection = () => {
    const sticker = getActiveSticker();

    if (sticker) {
      setSelection(boundsToSelection(sticker.bounds));
    } else {
      resetSelection();
    }
  };

  useEffect(() => {
    if (!activeSticker) {
      // reset selection whenever nothing is active anymore
      resetSelection();
      return;
    }

    syncSelection();
  }, [activeSticker]);

  const onClickCanvas = useCallback((e) => {
    if (isPlaying) {
      return;
    }

    const pos = getPosition(e);

    const active = isInSticker(pos);

    // if we found an active sticker to be dragging
    if (active) {
      setActiveId(active.id);
      return;
    }

    // remove active id if we click elsewhere
    setActiveId(null);
  }, [isPlaying]);

  const handleDrag = (pos) => {
    const tr = useUiStore.getState().translation;
    const dX = pos.x - tr.x1;
    const dY = pos.y - tr.y1;

    // update the translation
    const bounds = {
      ...tr.initBounds,
      left: tr.initBounds.left + dX,
      top: tr.initBounds.top + dY,
    };

    setTranslation({
      ...tr,
      x2: pos.x,
      y2: pos.y,
      bounds,
    });
  };

  const handleIsOver = (pos) => {
    const active = isInSticker(pos);
    if (active && !useUiStore.getState().isOverSticker) {
      setIsOverSticker(true);
    } else if (!active && useUiStore.getState().isOverSticker) {
      setIsOverSticker(false);
    }
  };

  const handleMouseMove = useCallback((e) => {
    if (isPlaying) {
      return;
    }

    const buffer = getBuffer()
    const pos = getPosition(e);

    if (resizing.active) {
      handleResize(pos, buffer, resizing);
      return;
    }

    // if we're active and we're dragging
    if (activeId && useUiStore.getState().isDraggingSticker) {
      handleDrag(pos);
      return;
    }

    handleIsOver(pos);
  }, [isPlaying, activeId, resizing]);

  const handleMouseUp = () => {
    const sticker = getActiveSticker();
    if (sticker) {
      const tr = useUiStore.getState().translation;
      const activeId = useUiStore.getState().activeId;
      const selection = useUiStore.getState().selection;
      const resizing = useUiStore.getState().resizing;

      if (tr.id === sticker.id && tr.bounds) {
        updateSticker(sticker.id, { bounds: tr.bounds });
      }

      if (activeId === sticker.id && resizing?.active) {
        const bounds = selectionToBounds(selection)
        updateSticker(sticker.id, { bounds });
      }

      // either use the tr bounds coming thru or the sticker bounds
      setSelection(boundsToSelection(tr.bounds || sticker.bounds));
    }

    resetTranslation();
    resetResizing();
    setIsDraggingSticker(false);
  };

  const handleMouseLeave = () => {
    handleMouseUp();
  };

  const wrapperSize = useMemo(() => getWrapperSize(), []);

  const isStripe = () => {
    if (!getStripeImage()) {
      return false;
    }

    return !getUserImg();
  }

  const onGetImageSize = () => {
    const img = getUserImg();
    const stripeData = getStripeLayers()[0];
    const stripe = stripeData?.bounds ? getSize(stripeData.bounds) : null;
    return img || stripe || initialState.size;
  };

  const onSetupBaseCanvas = () => {
    const canvas = document.createElement("canvas")
    const ctx = canvas?.getContext("2d");

    const imgSize = onGetImageSize();
    const base = onGetSize(imgSize);

    const currImgSize = useAppStore.getState().size;
    const currCanvasSize = useAppStore.getState().canvasSize;

    const isDiffSize = (s, p) => s && (s.width !== p?.width || s.height !== p?.height);

    if (isDiffSize(imgSize, currImgSize)) {
      useAppStore.setState({ size: imgSize });
    }

    const canvasSize = onGetCanvasSize(base);

    if (isDiffSize(canvasSize, currCanvasSize)) {
      useAppStore.setState({ canvasSize });
    }

    scaleCanvas(canvas, ctx, canvasSize.width, canvasSize.height);

    // apply the gradient to the background of the canvas
    layerRenderer.gradient({
      ctx,
      canvas,
      gradient: useAppStore.getState().gradient,
    });

    if (!isStripe()) {
      // now integrate the base layer
      layerRenderer.baseimage({
        ctx,
        canvas,
        layerData: getUserImg(),
      });
    } else {
      // otherwise we'll just draw the foundation for the stripe background
      drawCenteredRoundedImage(ctx, null)
    }

    useAppStore.setState({ baseCanvas: { ctx, canvas } })
  }

  const onSetupCanvas = () => {
    const { ctx, canvas } = getCtx();

    const base = useAppStore.getState().baseCanvas

    if (!base) {
      return;
    }

    // clears the canvas for a fresh redraw
    // may need to write this in a bgackward compatible way
    ctx.reset();

    const width = base.canvas.width / 2
    const height = base.canvas.height / 2

    // scale the current canvas
    scaleCanvas(canvas, ctx, width, height)

    ctx.drawImage(base.canvas, 0, 0, width, height)
  }

  const onDownloadImage = () => {
    const { canvas } = getCtx();
    downloadImage(canvas);
  };

  useEffect(() => {
    const instances = getAnimationLayers(useAppStore.getState());

    for (const instance of instances) {
      if (isPlaying) {
        instance.animation?.animation?.goToAndPlay(0, true);
      } else {
        instance.animation?.animation?.goToAndStop(0, true);
      }
    }

    if (!isPlaying) {
      onDraw();
    }
  }, [isPlaying]);

  useInterval(() => {
    // if we're recording use the recording length, otherwise the baisc frame length
    const isRecording = useAppStore.getState().isRecording;
    const exportFormat = useAppStore.getState().exportFormat;

    const end = isRecording && exportFormat === "mp4"
      ? getRecordingLength()
      : getFrameLength();

    const curr = useAppStore.getState().frame;

    if (curr <= end) {
      incFrame();
      return;
    }

    if (isRecording) {
      // recording will be stopped via the media recorder
      recorder.stop();
    } else {
      setIsPlaying(false);
    }
  }, isPlaying ? 1000 / fps : null);

  useEffect(() => {
    if (!isRecording) {
      setIsPlaying(false)
    }
  }, [isRecording])

  const onDrawLayer = (layer, frame) => {
    const { ctx, canvas } = getCtx();

    const layerData = getLayer(layer.id); // pulls in the graph

    if (layer.type === "watermark") {
      layerRenderer.watermark({ ctx, canvas });
      return;
    }

    const data = {
      ctx,
      mode: useAppStore.getState().mode,
      canvas,
      layer,
      layerData,
      frame,
    };

    if (layer.type === "sticker") {
      layerRenderer.sticker(data);
      return;
    }

    if (layer.type === "stripe") {
      layerRenderer.stripe({
        ...data,
        // frame: getFrameLength() - 1,
        layerData: {
          ...layerData,
          numFrames: layer.end - layer.start,
        },
      });
      return;
    }

    if (layer.type === "counter") {
      layerRenderer.counter(data);
      return;
    }

    if (layer.type === "linegraph") {
      layerRenderer.linegraph(data);
      return;
    }
  };

  const onDrawTimeline = (frame) => {
    const timeline = useAppStore.getState().timeline;
    const isRecording = useAppStore.getState().isRecording;
    const sorted = sortTimeline(timeline);

    const numFrames = getFrameLength();
    const lastFrame = numFrames - 1;

    // make sure we don't go over the available frames
    const tlFrame = Math.min(frame === -1 ? lastFrame : frame, lastFrame);
    const isLastFrame = tlFrame === lastFrame;

    // stickers and the base layer never change so lets not update if there the only ones
    // TODO: THIS IS MESSING UP THE RECORDING I'M PRETTY SURE?
    const isActive = tlFrame === 0 || isLastFrame || !isRecording || timeline.some((s) => {
      return s.type !== "base" && tlFrame >= s.start && tlFrame <= s.end;
    });

    if (!isActive) {
      // return;
    }

    // first step is to setup the canvas
    onSetupCanvas();

    for (const layer of sorted) {
      // we'll only want to start the frames when its supposed to start
      const f = Math.max(tlFrame - layer.start, 0);

      onDrawLayer(layer, f);
    }

    if (getIsWatermarkVisible()) {
      onDrawLayer({ type: "watermark" })
    }
  };

  // we only want to draw the first frame when we're designing
  const onDraw = () => {
    // if we're recording reset this to
    onDrawTimeline(-1);
  };

  useEffect(() => {
    const handleFrame = (s, p) => {
      if (s.frame !== p.frame) {
        onDrawTimeline(s.frame);
      }
    };

    const handleDraw = (s, p) => {
      const fields = ["baseCanvas", "session"];
      // if any of this changes lets re-draw the canvas
      if (fields.some((field) => s[field] !== p[field])) {
        onDraw(); // this should acutally update this, including the counters within them
      }
    }

    const handleBase = (s, p) => {
      const fields = ["timeline", "buffer", "gradient", "radius", "shadow", "images", "layers", "aspectRatio"];
      // if any of this changes lets re-draw the canvas
      if (fields.some((field) => s[field] !== p[field])) {
        onSetupBaseCanvas(); // this should acutally update this, including the counters within them
      }
    }

    // listen for updates in the app store
    const unsub = useAppStore.subscribe((s, p) => {
      handleFrame(s, p);
      handleBase(s, p);
      handleDraw(s, p);
    });

    return unsub;
  }, []);

  const onPreview = () => {
    // if we're already previewing, lets stop it and reset
    if (getIsPlaying()) {
      resetTimeline();
      return;
    }

    restartTimeline();
  };

  const onDownloadWebm = async (blob) => {
    try {
      downloadVideoFromStorage(URL.createObjectURL(blob), 'snapmate.webm')
    } catch (e) {
      console.error(e)
      // fallback to generating webm
      toast.error("Failed to generate webm. Please try again")
    }
  }

  const onDownloadMp4 = async (blob, endTimeOffset) => {
    try {
      // transcode and trim to the duration of the endTimeOffset
      const videoURL = await transcode(blob, endTimeOffset);
      // download the video
      if (videoURL) {
        downloadVideoFromStorage(videoURL, `snapmate.mp4`);
      }
    } catch (e) {
      console.error(e)
      // fallback to generating webm
      toast.error("Failed to generate mp4. Downloading as webm instead")
      downloadVideoFromStorage(URL.createObjectURL(blob), 'snapmate.webm')
    }
  }

  // format can be either mp4 or webm
  const onExport = async (format) => {
    if (format === "mp4" && getIsPaywallVisible()) {
      setIsPaywallOpen(true);
      return;
    }

    // set the format so we can adjust duration etc.
    setExportFormat(format);

    onDrawTimeline(0);

    setIsRecording(true);

    // the actual length the video should be, transcoder api will trim it down to this
    const endTimeOffset = getDuration()

    recorder.record(async (chunks, duration) => {
      // if we're still recording then we'll shut this down
      if (useAppStore.getState().isRecording) {
        const blob = await chunksToFormattedBlob(chunks, duration);

        try {
          if (format === "webm") {
            await onDownloadWebm(blob);
          }

          if (format === "mp4") {
            await onDownloadMp4(blob, endTimeOffset);
          }
        } catch (e) {
          console.error(e)
        }

        setIsRecording(false);
        setStatus(null)
      }
    });

    onPreview();
  };

  const onUndo = () => {
    undo();

    requestAnimationFrame(() => {
      syncSelection();
    });
  };

  // hook to handle undo / redo via key presses
  useUndo(onUndo);

  const onDelete = () => {
    const id = useUiStore.getState().activeId;
    if (id) {
      removeLayer(id);
      resetSelection();
      setActiveId("");
      return true;
    }
    return false;
  };

  useBackspace(onDelete);

  const onStopRecording = () => {
    // stop the recording
    if (useAppStore.getState().isRecording) {
      // set this first so it doesn't attempt to export the video
      setIsRecording(false);
      recorder.stop();
    }

    // stopping the recording will happen within the media stream listener
    setIsPlaying(false);
  };

  const onTogglePlaying = () => {
    if (getIsPlaying()) {
      onStopRecording();
      return;
    }

    onPreview();
  };

  // TODO: DISABLE FOR NOW AS THERES SOME EDGE CASE STUFF
  useSpaceBar(() => {
    if (useAppStore.getState().mode === "video") {
      onTogglePlaying();

      return true;
    }
    return false;
  });

  const onDragOverRoot = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
  };

  const [overlayActive, setIsOver] = useState(false);

  const initForImage = async (base64) => {
    const image = await capImageSize(base64, maxWidth, maxHeight);

    // preload the image in
    const img = await preloadImage(image);
    const id = nanoid();

    // add it to the state
    useAppStore.getState().addImage(id, img);
    
    // now add the base layer so its already in place
    addBaseImg(image, id);
    useAppStore.temporal.getState().clear();
  };

  const handleImageUpload = async (file) => {
    if (!file) {
      return;
    }

    // clear this
    setIsOver(false);

    const base64 = await fileToBase64(file);
    initForImage(base64);
  };

  const onDropRoot = async (ev) => {
    ev.preventDefault();

    const file = ev.dataTransfer?.files?.[0];
    handleImageUpload(file);
  };

  useEffect(() => {
    document.addEventListener("dragstart", (e) => {
      // console.dir(e.target);
      const code = e.target.dataset?.unified;
      isDraggingEmoji.current = true;
      useAppStore.setState({ draggingSticker: code });
      e.dataTransfer.setData("text", `emoji-${code}`);
    });

    document.addEventListener("dragend", () => {
      isDraggingEmoji.current = false;
      useAppStore.setState({ draggingSticker: null });
    });
  }, []);

  const onEmojiClick = (emoji) => {
    console.log(emoji);
  };

  const onUpload = (ev) => {
    const file = ev.target.files[0];
    handleImageUpload(file);
  };

  const onToggleMode = (toMode) => {
    setMode(toMode);
  }

  return (
    <div
      className="h-screen w-screen flex flex-row justify-center"
      onDragOver={onDragOverRoot}
      onDragEnter={(ev) => {
        if (!isDraggingEmoji.current) {
          setIsOver(true)
        }
      }}
      onDrop={onDropRoot}
    >
      <div
        className="flex-1 relative"
        style={{
          minWidth: 1024,
          maxWidth: 1536,
        }}
      >
        <div className="hidden lg:block">
          <div className="absolute top-4 left-4">
            <div className="flex flex-row gap-2 items-center">
              <a
                className={`btn btn-circle btn-neutral text-primary`}
                href="/"
              >
                <SparklesIcon className='w-6 h-6' />
              </a>
            </div>
          </div>
          <div className="absolute top-4 right-4">
            <UserButton />
          </div>
        </div>
        <div
          className="flex flex-col justify-center items-center"
        >
          <div
            className="p-2 flex-row flex items-center"
            style={{
              width: wrapperSize.width,
              height: 80,
            }}
            >
              <div className="flex flex-row gap-2 w-48">
                {!isRecording ? (
                  <>
                    <NewOptions isDisabled={isPlaying} />
                    <button
                      className="btn btn-circle btn-neutral"
                      onClick={onUndo}
                      disabled={isPlaying}
                    >
                      <ArrowUturnLeftIcon className="w-6 h-6" />
                    </button>
                    <button
                      className="btn btn-circle btn-neutral"
                      onClick={onDelete}
                      disabled={isPlaying || !activeId}
                    >
                      <TrashIcon className="w-6 h-6" />
                    </button>
                  </>
                ) : null}
              </div>
              <div className="flex-1 justify-center items-center flex">
                {!isRecording ? (
                  <Toggle
                    selected={mode}
                    onChange={onToggleMode}
                  />
                ) : null}
              </div>
            <div className="flex flex-row gap-2 justify-end">
              <ExportButton
                onExport={onExport}
                onDownloadImage={onDownloadImage}
                onStop={onStopRecording}
              />
              <div className="lg:hidden">
                <UserButton />
              </div>
            </div>
          </div>
          <div
            style={{
              width: wrapperSize.width,
              height: wrapperSize.height,
            }}
            className="flex justify-center items-center bg-base-200 rounded-lg relative"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <div
              onClick={onClickCanvas}
              onDrop={onDrop}
              onDragOver={onDragOver}
              id="wrapper"
              className="relative canvas-wrapper"
            >
              <canvas
                id="canvas"
              />
              {!timeline.length ? (
                <div
                  className="w-full h-full absolute top-0 left-0 flex justify-center items-center"
                >
                  <EmptyCanvasOptions onUpload={onUpload} />
                </div>
              ) : null}
              {!isPlaying && !isDraggingSticker ? (
                <SelectionBox
                  isResizing={resizing.active}
                />
              ) : null}
            </div>
            {mode === "video" ? (
              <div className="absolute bottom-0 left-auto right-auto">
                <PlayButton
                  onClick={onTogglePlaying}
                  isPlaying={isPlaying}
                  isDisabled={!timeline.length}
                />
              </div>
            ) : null}
            {!isPlaying && !isRecording ? (
              <>
                <div className="absolute bottom-0 left-0 p-4">
                  <EmojiPicker onEmojiClick={onEmojiClick} />
                </div>
                <div className="absolute top-0 right-0 p-4">
                  <CanvasOptions />
                </div>
                <div className="absolute bottom-0 right-0 p-4">
                  <GradientOptions />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {overlayActive ? (
        <div
          className="fixed inset-0 backdrop-blur"
          onDragLeave={() => setIsOver(false)}
        />
      ) : null}
      <EmojiSuggestionModal />
      <AuthModal
        isOpen={isAuthModalOpen && isAuthModalVisible}
        isPaywallOpen={isPaywallOpen && isPaywallVisible}
        onClose={() => setIsAuthModalOpen(false)}
      />
      <PaywallModal
        isOpen={isPaywallOpen && isPaywallVisible && !(isAuthModalOpen && isAuthModalVisible)}
        onClose={() => setIsPaywallOpen(false)}
      />
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        toastOptions={{
          className: 'px-3 py-2',
          style: {
            color: "var(--fallback-bc,oklch(var(--bc)/var(--tw-text-opacity)))",
            backgroundColor: "oklch(0.3451965 0.021108 254.139)",
          },
        }}
      />
    </div>
  )
}

export default App;
