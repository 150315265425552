import { useEffect } from "react";

export { useKeyPress, useBackspace, useSpaceBar }

const useKeyPress = (key, cb) => {
  useEffect(() => {
    const listener = function(evt) {
      if (evt.key === key) {
        // handle redo action
        const isHandled = cb();
        if (isHandled) {
          evt.stopImmediatePropagation();
          // prevent the default action
          evt.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', listener);
    return () => document.removeEventListener("keydown", listener);
  }, []);
};

const useBackspace = (cb) => useKeyPress("Backspace", cb);

const useSpaceBar = (cb) => useKeyPress(" ", cb);
