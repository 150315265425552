import { PhotoIcon } from "@heroicons/react/24/solid";
import stripe from "./assets/stripe.svg";
import twitter from "./assets/twitter.svg";
import { config } from "./config";
import { isInstalled } from "./extension";

export { EmptyCanvasOptions, onStripe }

const onStripe = async () => {
  try {
    // if this resolves fine then it's installed
    await isInstalled();

    // send them to stripe
    window.open("https://dashboard.stripe.com/dashboard", "_blank");
  } catch (e) {
    // send them to extension page
    window.open("https://chromewebstore.google.com/detail/gbdcgjokbmfoaaedmgakmhejfjinjmmn", "_blank");
    console.error(e);
  }
};

const Option = ({ src, onClick, className }) => {
  return (
    <div
      className={`w-16 h-16 rounded-md justify-center items-center flex cursor-pointer ${className}`}
      onClick={onClick}
    >
      <img src={src} />
    </div>
  );
};

const EmptyCanvasOptions = ({ onUpload }) => {
  return (
    <div className="flex gap-2 flex-1 justify-center">
      {/* <div className="flex-1 flex justify-end"> */}
      <div className="flex justify-end">
        <input
          type="file"
          id="imageUpload"
          name="imageUpload"
          accept="image/*"
          onChange={onUpload}
          hidden="hidden"
        ></input>
        <label
          htmlFor="imageUpload"
          className="btn bg-neutral btn-lg"
        >
          Upload Image
          {/* <VideoCameraIcon className="w-8 h-8" /> */}
          <PhotoIcon className="w-8 h-8" />
        </label>
      </div>
      <div className="divider divider-horizontal divider-neutral text-neutral">
        {/* OR */}
      </div> 
      {/* <div className="flex-1"> */}
      <div className="flex flex-row gap-2">
        {config.networks.includes("stripe") ? (
          <Option
            src={stripe}
            onClick={onStripe}
            className="stripe-btn"
          />
        ) : null}
        {config.networks.includes("twitter") ? (
          <div className="tooltip tooltip-top" data-tip="Coming soon">
            <Option
              src={twitter}
              className="p-1 twitter-btn"
              onClick={() => null}
            />
          </div>
        ) : null}
        <div
          className="w-16 h-16" // for spacing
        />
      </div>
    </div>
  );
};
