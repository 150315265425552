import { UserIcon } from "@heroicons/react/24/solid";
import { useAppStore } from "./stores/useAppStore"
import supabase from "./supabase/client";
import { useState } from "react";
import { Button, DropDownContent, Option } from "./components/DropDown";

export { UserButton }

const UserButton = () => {
  const session = useAppStore(s => s.session);
  const [isLoading, setIsLoading] = useState(false);

  const onSignOut = async () => {
    useAppStore.setState({ session: null });

    await supabase.auth.signOut();
  };

  const onSubscriptions = async () => {
    setIsLoading(true);

    try {
      const token = useAppStore.getState().session?.access_token;
  
      const res = await fetch(`/api/create-portal-session`, {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
  
      const data = await res.json();
  
      if (data?.url) {
        window.open(data.url, "_blank")
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  if (session) {
    return (
      <div className="dropdown dropdown-bottom dropdown-end">
        <Button className="shadow-lg btn btn-circle btn-neutral">
          <UserIcon className="w-6 h-6" />
        </Button>
        <DropDownContent className="bg-neutral w-60">
          {/* <Option onClick={onSubscriptions}>
            Manage Subscription
            {isLoading ? (
              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : null}
          </Option> */}
          <Option onClick={onSignOut}>
            Sign out
          </Option>
        </DropDownContent>
      </div>
    );
  }

  return (
    <button
      className="btn btn-neutral"
      onClick={() => {
        document.getElementById("auth-modal").showModal();
      }}
    > 
      Sign In
    </button>
  );
};
