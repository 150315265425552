import { Modal } from "./Modal";

export {
  EmojiSuggestionModal,
  openEmojiSuggestionModal,
}

const id = `emoji-suggestion-modal`;

const openEmojiSuggestionModal = () => {
  document.getElementById(id).showModal();
};

const EmojiSuggestionModal = () => {
  return (
    <Modal id={id}>
      <div>
        Try adding emojis to see the animation in effect
      </div>
    </Modal>
  );
};
