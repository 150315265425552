const blacklist = (keys) => (state) => {
  return Object.fromEntries(Object.entries(state).filter(([key]) => !keys.includes(key)),);
}

const whitelist = (keys) => (state) => {
  return Object.fromEntries(Object.entries(state).filter(([key]) => keys.includes(key)),);
}

export const partialize = {
  whitelist,
  blacklist,
}

export const toggleElement = (array, element) => {
  const index = array.indexOf(element);

  if (index === -1) {
    return [...array, element];
  }

  // Element in array, so remove it
  const dup = [...array];
  dup.splice(index, 1);
  return dup;
}