import { create } from 'zustand';

export { useUiStore, updateSelection };

const initialState = {
  tempBuffer: null,
  translation: { x1: 0, y1: 0, x2: 0, y2: 0 },
  resizing: {
    direction: "",
    cursor: "",
    active: false,
    initial: { x1: 0, y2: 0, x2: 0, y2: 0 },
  },
  selection: { x1: 0, y2: 0, x2: 0, y2: 0 },
  activeId: null,
  isDraggingSticker: false,
  isOverSticker: false,
};

const useUiStore = create((set, get) => ({
  ...initialState,
  setSelection: (val) => set({ selection: val }),
  setTranslation: (val) => set({ translation: val }),
  setTempBuffer: (val) => set({ tempBuffer: val }),
  setResizing: (resizing) => set({ resizing }),
  setIsDraggingSticker: (val) => set({ isDraggingSticker: val }),
  setIsOverSticker: (val) => set({ isOverSticker: val }),
  setActiveId: (activeId) => set({ activeId }),
  resetStore: () => set({ ...initialState }),
  resetResizing: () => set({ resizing: initialState.resizing }),
  resetTranslation: () => set({ translation: initialState.translation }),
  resetSelection: () => set({ selection: initialState.selection }),
}));

const updateSelection = (val) => {
  return useUiStore.setState({
    selection: {
      ...useUiStore.getState().selection,
      ...val,
    },
  })
};
