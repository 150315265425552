import { selectionToBounds } from "../ResizeElement";
import { getAnimation } from "../stores/useAppStore";
import { useUiStore } from "../stores/useUiStore";
import { boundsWithBuffer, resetTransform } from "../util";

export { draw }

const getBounds = (sticker) => {
  const tr = useUiStore.getState().translation;
  const activeId = useUiStore.getState().activeId;
  const selection = useUiStore.getState().selection;
  const resizing = useUiStore.getState().resizing;
  
  let bounds = sticker.bounds;

  // handle resizing first
  if (resizing?.active && activeId === sticker.id) {
    bounds = selectionToBounds(selection);
  } else if (tr?.id === sticker.id && tr?.bounds) { // handle translation second
    bounds = tr.bounds;
  }

  return boundsWithBuffer(bounds);
};

const draw = ({ ctx, mode = "image", layerData: sticker }) => {
  if (!sticker?.bounds) {
    return;
  }

  const res = getAnimation(sticker.lottieUrl);
  
  let img = null;
  
  if (res?.canvas) {
    img = res.canvas;
  }

  const { transform, emoji } = sticker;
  const { rotation } = transform;
  const bounds = getBounds(sticker);
  const { left, top, width: w, height: h } = bounds;
  const angleInRadians = rotation * (Math.PI / 180); // 45 degrees

  const tX = left + (w / 2);
  const tY = top + (h / 2);

  // console.log(sticker);
  // Translate to the center of the image
  ctx.translate(tX, tY);
  // apply rotation
  ctx.rotate(angleInRadians);
  if (mode === "image" || (mode === "video" && !img)) {
    if (emoji) {
      const fontSize = w;
      ctx.font = `${fontSize}px "Noto Color Emoji"`;
      ctx.textAlign = "left";
      ctx.textBaseline = "top";
      const lineHeight = ctx.measureText(emoji).width;
      const s = w / lineHeight;
      ctx.font = `${fontSize * s}px "Noto Color Emoji"`;
      const y = -((h * s * s) / 2);
      ctx.fillText(emoji, -w / 2, y);
    }
  } else if (img) {
    // draw the image
    ctx.drawImage(img, -w / 2, -h / 2, w, h); // Draw the image
  }

  // console.log("drawing image", w, h, img, tX, tY, angleInRadians);
  resetTransform(ctx);
};