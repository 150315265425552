import { drawLine, scaleCanvas } from "../../draw";
import { easeOutCubic } from "../../ease";

export { draw, drawChart };

const font = `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;

const drawAxis = (context, yAxis, xAxis) => {
  const { ctx, width, height, bounds, fontSize } = context;

  const w = width;
  const h = height;

  ctx.font = `400 ${fontSize}px ${font}`;
  ctx.fillStyle = "#687385";
  ctx.textAlign = "left";
  ctx.fillText(yAxis.max, 0, fontSize);
  ctx.fillText(yAxis.min, 0, h - bounds.bottom - 4);
  ctx.textAlign = "left";
  ctx.fillText(xAxis.min, bounds.left, h);
  ctx.textAlign = "right";
  ctx.fillText(xAxis.max, w - bounds.right, h);
};

const onDrawLine = (context) => {
  const { ctx, width, height, bounds } = context;

  const w = width;
  const h = height;

  const y = h - bounds.bottom;
  const pts = [[bounds.left - 40, y], [w - bounds.right, y]];
  const l = bounds.left;
  const dash1 = [[l, y], [l, y + 5]];
  const r = w - bounds.right;
  const dash2 = [[r, y], [r, y + 5]];

  const drawL = (line) => {
    drawLine(ctx, line, line, { top: 0, left: 0, width: w, height: h }, "#BFC8D2", 1);
  };

  drawL(pts);
  drawL(dash1);
  drawL(dash2);
};

const onDrawMask = (context, image) => {
  const { ctx, width, height, i, numFrames} = context;

  const percent = easeOutCubic(i / numFrames);
  const w = Math.floor(percent * width);

  if (w > 0) {
    ctx.drawImage(image, 0, 0, w * 2, height * 2, 0, 0, w, height)
  }
};

const drawChart = ({ i, width, height, image, bounds, axis, numFrames, fontSize }) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  scaleCanvas(canvas, ctx, width, height);

  const context = {
    canvas,
    ctx,
    width,
    height,
    bounds,
    numFrames,
    fontSize,
    i,
  };

  onDrawLine(context);
  onDrawMask(context, image);
  drawAxis(context, axis.y, axis.x);

  return { canvas, ctx };
};

const paddingTop = 90;
const padding = 40;

const draw = ({ i, width, height, image, bounds, axis, numFrames, fontSize, title, summary }) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const h = height + paddingTop + (padding * 2);
  const w = width + (padding * 2);

  // draws the chart
  const res = drawChart({
    i,
    width,
    height,
    image,
    bounds,
    axis,
    numFrames,
    fontSize,
  });
  
  // scale the canvas
  scaleCanvas(canvas, ctx, w, h);

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, w, h);

  // draw the canvas onto this canvas
  ctx.drawImage(res.canvas, 0, 0, res.canvas.width, res.canvas.height, padding, padding + paddingTop, width, height);

  // draw the title and summaries
  ctx.font = `600 16px ${font}`;
  ctx.fillStyle = "#353a44";
  ctx.fillText(title, 0 + padding, 20 + padding);

  ctx.font = `400 18px ${font}`;
  ctx.fillText(summary.line0, 0 + padding, 50 + padding);

  if (summary.line1) {
    const text = ctx.measureText(summary.line0);
    ctx.font = `400 14px ${font}`;
    ctx.fillStyle = "#596171";
    ctx.fillText(summary.line1, 0 + text.width + 10 + padding, 50 + padding);
  }

  return { canvas, ctx, width: w, height: h };
};
