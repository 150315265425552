import { getGradientPointsForAngle, gradients } from "../gradient";

export { draw };

const draw = ({ ctx, canvas, gradient }) => {
  const g = gradients.find(({ value }) => value === gradient) || gradients[0];

  const width = canvas.width / window.devicePixelRatio;
  const height = canvas.height / window.devicePixelRatio;

  const { x0, y0, x1, y1 } = getGradientPointsForAngle(width, height, g.angle);

  var grd = ctx.createLinearGradient(x0, y0, x1, y1);

  g.colors.forEach(([pos, color]) => grd.addColorStop(pos, color));

  // Fill with gradient
  ctx.fillStyle = grd;
  ctx.fillRect(0, 0, width, height);
};
