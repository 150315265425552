
import { draw as sticker } from "./sticker";
import { draw as counter } from "./counter";
import { draw as linegraph } from "./linegraph";
import { draw as stripe } from "./stripe";
import { draw as baseimage } from "./baseimage";
import { draw as gradient } from "./gradient";
import { draw as watermark } from "./watermark";

const layers = {
  sticker,
  counter,
  linegraph,
  stripe,
  baseimage,
  gradient,
  watermark,
}

export default layers;
