import { useEffect } from "react";

export { useUndo };

const useUndo = (cb) => {
  useEffect(() => {
    /**
     * Handle undo and redo via key commands
     */
    document.addEventListener('keydown', function(evt) {
      if (evt.key === 'z' && (evt.ctrlKey || evt.metaKey) && evt.shiftKey) {
        // handle redo action
        evt.stopImmediatePropagation();
        // redo();
        cb(false);
      } else if (evt.key === 'z' && (evt.ctrlKey || evt.metaKey)) {
        // handle undo action
        evt.stopImmediatePropagation();
        cb(true);
      }
    });
  }, []);
};
