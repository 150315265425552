import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import * as Sentry from "@sentry/react";
import './index.css'

Sentry.init({
  dsn: "https://640c636169824e22b0f337e9af07150a@o264286.ingest.us.sentry.io/1466179",
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
