// import { CameraIcon, PhotoIcon } from "@heroicons/react/24/solid";
import { FilmIcon, PhotoIcon } from "@heroicons/react/24/outline";

export { Toggle };

const Option = ({ isSelected, ...rest }) => {
  return (
    <div
      className={`rounded-full ${isSelected ? "bg-primary text-base-300" : "bg-base-300"} p-2 px-4 cursor-pointer`}
      {...rest}
    />
  );
};

const Toggle = ({ selected, onChange }) => {
  return (
    <div className="rounded-full bg-base-300 flex flex-row p-2">
      <div className="tooltip tooltip-left" data-tip="Basic">
        <Option
          isSelected={selected === "image"}
          onClick={() => onChange("image")}
        >
          <PhotoIcon className="w-6 h-6" />
          {/* <CameraIcon className="w-6 h-6" /> */}
        </Option>
      </div>
      <div className="tooltip tooltip-right" data-tip="Animated">
        <Option
          isSelected={selected === "video"}
          onClick={() => onChange("video")}
        >
          <FilmIcon className="w-6 h-6" />
          {/* <VideoCameraIcon className="w-6 h-6" /> */}
        </Option>
      </div>
    </div>
  );
};
