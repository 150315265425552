import { useState } from "react";
import { Modal } from "./Modal";
import { getIsAuthenticated, useAppStore } from "./stores/useAppStore";
import { CheckIcon } from "@heroicons/react/24/solid";

export { PaywallModal }

const Plan = ({ title, subtitle, price, priceId, btnLabel, isHighlighted, features = [], onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const setIsAuthModalOpen = useAppStore(s => s.setIsAuthModalOpen)

  const getUrl = async () => {
    if (!priceId) {
      onClose();
      return;
    }

    if (!getIsAuthenticated()) {
      setIsAuthModalOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      const token = useAppStore.getState().session?.access_token;
  
      const res = await fetch(`/api/create-checkout-session`, {
        method: "POST",
        body: JSON.stringify({ priceId }),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      });
  
      const data = await res.json();
  
      if (data?.url) {
        window.open(data.url, "_blank")
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  return (
    <div
      className={`flex-1 rounded-xl px-8 py-12 flex flex-col gap-6 border-2 border-solid ${isHighlighted ? "border-primary" : "border-neutral"}`}
    >
      <div className="text-lg">{title}</div>
      {/* <div className="text-sm">
        {subtitle}
      </div> */}
      <div className="text-4xl font-bold">
        {price}
      </div>
      <div
        className={`btn relative ${isHighlighted ? "btn-primary" : "bg-base-300 text-inherit"}`}
        onClick={getUrl}
      >
        {btnLabel}
        {isLoading ? (
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white absolute left-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        ) : null}
      </div>
      <div className="text-sm flex flex-col gap-2 pt-2">
        {features.map((feature) => (
          <div className="flex flex-row items-center gap-1">
            <div className="pr-2"><CheckIcon className="w-5 h-5 text-accent" /></div>
            <div className="flex-1">{feature}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const PaywallModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} id="paywall-modal" onClose={onClose} width="w-full max-w-2xl">
      <div className="flex flex-col gap-6 justify-center p-4 text-gray-200">
        <div className="label text-2xl">
          Choose what works best for you!
        </div>
        <div className="flex flex-row gap-4">
          <Plan
            title="Essential"
            subtitle="Get started"
            price="Free"
            btnLabel="Get Started"
            priceId=""
            onClose={onClose}
            features={[
              "Emojis",
              "Animated Emojis",
              "Image Export",
              "WebM Video Export",
              "3rd party integrations (Stripe, More coming soon)",
            ]}
          />
          <Plan
            isHighlighted
            title="Pro"
            subtitle="Everything else"
            price="$25"
            btnLabel="Purchase LIFE TIME Deal"
            priceId="price_1PSulZ4DbSv8OrOoGTKNjpzM"
            onClose={onClose}
            features={[
              "Everything in Starter",
              "MP4 Video Export",
              "No Watermark",
            ]}
          />
        </div>
      </div>
    </Modal>
  );
};
