import { useAppStore } from "./stores/useAppStore";
import { gradients, toStyle } from "./gradient";

export {
  GradientOptions,
};

const GradientOption = ({ gradient, ...rest }) => {
  const backgroundImage = toStyle(gradient);

  return (
    <div>
      <div
        key={gradient.value}
        className={`w-10 h-10 rounded-md cursor-pointer hover:scale-125 transition-all`}
        style={{ backgroundImage }}
        {...rest}
      />
    </div>
  );
};

const GradientOptions = () => {
  const gradient = useAppStore(s => s.gradient);
  const currGradient = gradients.find((g) => g.value === gradient);
  const setGradient = useAppStore(s => s.setGradient);

  return (
    <div className="dropdown dropdown-left dropdown-end">
      <div
        tabIndex={0}
        role="button"
        className="shadow-lg"
      >
        <GradientOption
          gradient={currGradient || gradients[0]}
        />
      </div>
      <div tabIndex={0} className="dropdown-content z-[1] card card-compact p-4 w-60 shadow bg-neutral mr-2">
        <div className="flex flex-row gap-4 items-center flex-wrap">
          {gradients.map((option) => {
            return (
              <GradientOption
                gradient={option}
                onClick={() => setGradient(option.value)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
