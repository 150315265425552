import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import RangeSlider from "./Range";
import { useAppStore, useBuffer } from "./stores/useAppStore";
import { useUiStore } from "./stores/useUiStore";
import { MAX_BUFFER, MAX_RADIUS, MAX_SHADOW, MIN_BUFFER, MIN_RADIUS, MIN_SHADOW } from "./sizes";

export {
  CanvasOptions,
};

const AspectOption = ({ ratio, label, onClick, value, isSelected }) => {
  const width = ratio < 1 ? 24 : 24 * ratio;
  const height = ratio < 1 ? 24 / ratio : 24;
  const val = value !== undefined ? value : ratio;

  return (
    <div
      className="flex flex-col items-center cursor-pointer gap-1"
      onClick={() => onClick(val)}
    >
      <div
        className={`border ${isSelected ? "border-gray-100" : "border-gray-600"} rounded-sm justify-center items-center flex`}
        style={{
          width,
          height,
        }}
      />
      <div>
        {label}
      </div>
    </div>
  );
};

const CanvasOptions = () => {
  const buffer = useBuffer();
  const shadow = useAppStore(s => s.shadow);
  const radius = useAppStore(s => s.radius);
  const setRadius = useAppStore(s => s.setRadius);
  const setShadow = useAppStore(s => s.setShadow);
  const setBuffer = useAppStore(s => s.setBuffer);
  const setAspectRatio = useAppStore(s => s.setAspectRatio);
  const aspectRatio = useAppStore(s => s.aspectRatio);

  const setTempBuffer = useUiStore(s => s.setTempBuffer);

  const onBufferChange = (e) => setTempBuffer(parseInt(e.target.value));

  const onRadiusChange = (e) => setRadius(parseInt(e.target.value));

  const onShadowChange = (e) => setShadow(parseInt(e.target.value));

  const onSliderStop = () => {
    // reset this
    if (useUiStore.getState().tempBuffer) {
      setBuffer(useUiStore.getState().tempBuffer);
      setTempBuffer(null);
    }
  };

  return (
    <div className="dropdown dropdown-bottom dropdown-end">
      <div
        tabIndex={0}
        role="button"
        className="bg-neutral w-11 h-11 rounded-md cursor-pointer hover:scale-125 transition-all justify-center items-center flex"
      >
        <AdjustmentsHorizontalIcon className="w-6 h-6" />
      </div>
      <div tabIndex={0} className="dropdown-content z-[1] card card-compact w-64 p-2 shadow bg-neutral mt-2">
        <div className="card-body">
          <div className="flex flex-col gap-1">
            <div>
              <div>
                <div className="py-1">Aspect Ratio</div>
                <div className="flex flex-row gap-2 items-end">
                  <AspectOption
                    ratio={5/4}
                    value={null}
                    isSelected={!aspectRatio}
                    label="None"
                    onClick={setAspectRatio}
                  />
                  <AspectOption
                    ratio={1}
                    label="1:1"
                    isSelected={aspectRatio === 1}
                    onClick={setAspectRatio}
                  />
                  <AspectOption
                    ratio={16/9}
                    label="16:9"
                    isSelected={aspectRatio === 16/9}
                    onClick={setAspectRatio}
                  />
                  <AspectOption
                    ratio={4/5}
                    label="4:5"
                    isSelected={aspectRatio === 4/5}
                    onClick={setAspectRatio}
                  />
                  <AspectOption
                    ratio={3/5}
                    label="3:5"
                    isSelected={aspectRatio === 3/5}
                    onClick={setAspectRatio}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="py-1">Padding</div>
              <RangeSlider
                value={buffer}
                onChange={onBufferChange}
                onComplete={onSliderStop}
                min={MIN_BUFFER}
                max={MAX_BUFFER}
                step={2}
              />
            </div>
            <div>
              <div className="py-1">Radius:</div>
              <RangeSlider
                value={radius}
                onChange={onRadiusChange}
                onComplete={onSliderStop}
                min={MIN_RADIUS}
                max={MAX_RADIUS}
                step={1}
              />
            </div>
            <div>
              <div className="py-1">Shadow</div>
              <RangeSlider
                value={shadow}
                onChange={onShadowChange}
                onComplete={onSliderStop}
                min={MIN_SHADOW}
                max={MAX_SHADOW}
                step={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
