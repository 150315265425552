import { config } from "./config";

const id = config.extensionId;

const getData = (key) => {
  return chrome.runtime.sendMessage(id, { action: "getData", key });
};

const isInstalled = () => {
  return chrome.runtime.sendMessage(id, { action: "health" });
}

export { getData, id, isInstalled }