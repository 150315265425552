export {
  getGradientPointsForAngle,
  toStyle,
  gradients,
}

function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

function rotateAngleBy180Degrees(angle) {
  let newAngle = angle + 90;
  if (newAngle >= 360) {
      newAngle -= 360;
  }
  return newAngle;
}

const getGradientPointsForAngle = (width, height, angleDegrees) => {
  const angleRadians = degreesToRadians(rotateAngleBy180Degrees(angleDegrees));
  const radius = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
  
  const cx = width / 2;
  const cy = height / 2;

  const x0 = cx + radius * Math.cos(angleRadians);
  const y0 = cy + radius * Math.sin(angleRadians);

  // To get the opposite end of the gradient line, add 180 degrees (or Math.PI radians)
  const x1 = cx - radius * Math.cos(angleRadians);
  const y1 = cy - radius * Math.sin(angleRadians);

  return { x0, y0, x1, y1 };
}

const toStyle = ({ angle, colors }) => {
  const colorStr = colors.map((color) => `${color[1]} ${color[0] * 100}%`).join(", ");
  return `linear-gradient(${angle}deg, ${colorStr})`;
};

const gradients = [
  { value: "g6", angle: 90, colors: [[0,  "rgba(59,158,255,1)"], [.9, "rgba(246,135,255,1)"]] },
  { value: "g2", angle: 160, colors: [[0, '#0093E9'],[1, '#80D0C7']] },
  { value: "g3", angle: 45, colors: [[0, '#85FFBD'], [1, '#FFFB7D']] },
  { value: "g4", angle: 0, colors: [[0, "#f78ca0"], [.19, "#f9748f"], [.60, "#fd868c"], [1, "#fe9a8b"]] },
  { value: "g1", angle: 43, colors: [[0, '#4158D0'],[0.46, '#C850C0'],[1, '#FFCC70']] },
];
