import { drawText } from "../draw";
import { useAppStore } from "../stores/useAppStore";
import { boundsWithBuffer } from "../util";

export { draw };

const draw = ({ ctx, layer, layerData: counter, frame }) => {
  if (!counter?.values) {
    return;
  }

  const f = Math.max(frame - layer.start, 0);
  const last = counter.values[counter.values.length - 1];
  const start = counter.values[0];

  if (!useAppStore.getState().isPlaying) {
    drawText(ctx, counter, last, boundsWithBuffer(counter.textBounds));
    return;
  }

  let value = counter.values[f] ?? last;

  if (frame < layer.start) {
    value = start;
  }

  if (frame > layer.end) {
    value = last;
  }

  drawText(ctx, counter, value, boundsWithBuffer(counter.textBounds));
};
