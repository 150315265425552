import { ArrowDownTrayIcon, VideoCameraIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import { getIsSubscribed, useAppStore, useIsPlaying, useIsSubscribed } from "./stores/useAppStore";
import { Button } from "./components/DropDown";

export { ExportButton }

const statusMap = {
  recording: "Recording...",
  uploading: "Uploading...",
  transcoding: "Converting to mp4..."
}

const DropDownContent = ({ className = '', children }) => {
  return (
    <div tabIndex={0} className={`dropdown-content z-[1] menu rounded-box py-4 shadow mt-2 ${className}`}>
      {children}
    </div>
  )
}

const Option = ({ className = '', ...props }) => {
  return (
    <li>
      <a className={`rounded-lg cursor-pointer w-full px-4 py-2 flex flex-row justify-between ${className}`} {...props} />
    </li>
  );
};

const ExportButton = ({ onStop, onExport, onDownloadImage }) => {
  const isRecording = useAppStore(s => s.isRecording);
  const status = useAppStore(s => s.status);
  const mode = useAppStore(s => s.mode);

  const isPlaying = useIsPlaying()

  const isSubscribed = useIsSubscribed();

  if (mode === "image") {
    return (
      <Button
        className={`btn btn-primary`}
        onClick={onDownloadImage}
      >
        <ArrowDownTrayIcon className="w-6 h-6" />
        Export Image
      </Button>
    )
  }

  if (isRecording) {
    return (
      <div
        role="button"
        className="btn btn-error"
        onClick={onStop}
      >
        <VideoCameraIcon className="w-6 h-6 animate-pulse" />
        {statusMap[status] || statusMap.recording}
      </div>
    )
  }

  return (
    <div className="dropdown dropdown-bottom dropdown-end">
      <Button
        className={`btn btn-primary ${isPlaying ? "btn-disabled" : ""}`}
      >
        <ArrowDownTrayIcon className="w-6 h-6" />
        Export Video
      </Button>
      <DropDownContent className="bg-neutral w-32">
        <Option
          onClick={() => onExport("webm")}
        >
          <div />
          WebM
        </Option>
        <Option
          onClick={() => onExport("mp4")}
        >
          {!isSubscribed ? (
            <LockClosedIcon className="w-6 h-6" />
          ) : <div />}
          MP4
        </Option>
      </DropDownContent>
    </div>
  )
};
