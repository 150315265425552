export const DropDownContent = ({ className = '', children }) => {
  return (
    <div tabIndex={0} className={`dropdown-content z-[1] menu py-4 shadow ${className}`}>
      <div className="flex flex-col gap-2 items-center flex-wrap px-2">
        {children}
      </div>
    </div>
  )
}

export const Option = ({ className = '', ...props }) => {
  return (
    <div className={`hover:bg-base-100 rounded-lg cursor-pointer w-full px-4 py-2 flex flex-row gap-2 items-center ${className}`} {...props} />
  );
};

export const Button = (props) => {
  return (
    <div
      tabIndex={0}
      role="button"
      {...props}
    />
  );
}
