import * as chart from "../charts/stripe/chart";
import { drawCenteredImage } from "../draw";
import { getImage } from "../stores/useAppStore";

export { draw, getSize };

const draw = ({ ctx, layerData, frame }) => {
  const {
    bounds,
    numFrames,
    title,
    summary,
    axis,
    img,
  } = layerData;

  const image = getImage(img?.id);
  if (!image) {
    return;
  }

  const res = chart.draw({
    i: frame,
    width: bounds.width,
    height: bounds.height,
    image,
    bounds,
    axis,
    numFrames,
    title,
    summary,
    fontSize: 12,
  });

  drawCenteredImage(ctx, res.canvas);
};

const paddingTop = 90;
const padding = 40;

const getSize = (bounds) => {
  const h = bounds.height + paddingTop + (padding * 2);
  const w = bounds.width + (padding * 2);

  return {
    width: w,
    height: h,
  }
};
