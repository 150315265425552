export {
  emojiToLottie,
  emojiToImage,
  unicodeToEmoji,
  unicodeEmojiToImage,
}

const emojiToLottie = (code) => {
  return `https://fonts.gstatic.com/s/e/notoemoji/latest/${code}/lottie.json`;
};

const emojiToImage = (emoji) => {
  return `https://emojicdn.elk.sh/${emoji}?style=google`;
};

const unicodeToEmoji = (code) => {
  return String.fromCodePoint(`0x${code}`);
};

const unicodeEmojiToImage = (code) => {
  const emoji = unicodeToEmoji(code);
  return `https://emojicdn.elk.sh/${emoji}?style=google`;
};
