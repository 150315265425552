import { useEffect, useState } from "react";
import { Modal } from "./Modal";
import supabase from "./supabase/client";
import { EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import { useAppStore } from "./stores/useAppStore";

export { AuthModal }

const AuthModal = ({ isOpen, onClose, isPaywallOpen }) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const setIsPaywallOpen = useAppStore(s => s.setIsPaywallOpen);

  const reset = () => {
    setEmail("");
    setIsSubmitted(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.getElementById("auth-modal").showModal();
      reset();
    } else {
      document.getElementById("auth-modal").close();
    }
  }, [isOpen]);

  const onSubmit = async () => {
    setIsSubmitting(true);
    
    try {
      const { error } = await supabase.auth.signInWithOtp({ email });

      if (error) {
        console.error(error);
      }
    } catch (e) {
      console.error(e);
    }

    setIsSubmitting(false);
    setIsSubmitted(true);
  };

  const _onClose = () => {
    setIsPaywallOpen(false);

    onClose();
  }

  return (
    <Modal id="auth-modal" isOpen={isOpen} onClose={_onClose}>
      <div className="flex flex-col gap-6 justify-center py-6 px-8">
        {isSubmitted ? (
          <div className="flex flex-col items-center gap-6">
            <EnvelopeOpenIcon className="w-12 h-12 text-primary" />
            <div className="text-2xl font-md">
              Check your email
            </div>
            <div className="flex flex-col gap-2 items-center text-neutral-content">
              <div>
                We emailed a link to
              </div>
              <div className="font-bold text-base-content">
                {email}
              </div>
              <div>
                Click the link to login or signup
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-6">
              <div className="text-2xl">
                Create an account
              </div>
              <div className="text-sm text-neutral-content">
                {isPaywallOpen ? (
                  <div>
                    First create an account to purchase the <span className="text-primary font-bold">Life Time Deal</span> with
                  </div>
                ) : (
                  <div>
                    What is your sign-in email address
                  </div>
                )}
              </div>
              <label className="input input-bordered flex items-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" /><path d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" /></svg>
                <input
                  type="email"
                  className="grow bg-inherit"
                  placeholder="Email"
                  autoComplete="email"
                  value={email}
                  onChange={(ev) => setEmail(ev.target.value)}
                />
              </label>
            </div>
            <button className="btn btn-primary" onClick={onSubmit} disabled={isSubmitting}>
              {!isSubmitting ? "Sign In With Email Address" : "Sending email..."}
            </button>
          </>
        )}
          {/* <GoogleLogin /> */}
      </div>
    </Modal>
  );
};
