import { drawCenteredRoundedImage } from "../draw";
import { isCanvas } from "../stores/useAppStore";

export { draw };

const draw = ({ ctx, layerData: img }) => {
  if (!isCanvas(img)) {
    return;
  }
  
  drawCenteredRoundedImage(ctx, img);
};
