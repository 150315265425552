import { PlayIcon, StopIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react';
import { getFrameLength } from './util';
import { useAppStore } from './stores/useAppStore';

export { PlayButton }

const useProgress = (isPlaying) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const numFrames = getFrameLength();

    const unsub = useAppStore.subscribe((s, p) => {
      if (s.frame !== p.frame) {
        setValue((s.frame / numFrames) * 100);
      }
    });

    return unsub;
  }, [isPlaying]);

  return !isPlaying ? 0 : value;
};

const PlayButton = ({ isPlaying, isDisabled, onClick }) => {
  const progress = useProgress(isPlaying);

  return (
    <div className={`p-4 flex justify-center items-center hover:opacity-100 ${isPlaying ? "opacity-60" : ""}`}>
      {isPlaying ? (
        <div
          className={`radial-progress absolute text-accent`}
          style={{ "--value": progress }}
        >
        </div>
      ) : null}
      <div
        className={`btn btn-circle bg-neutral text-accent btn-lg z-10`}
        style={{
          opacity: isDisabled ? 0.7 : 1,
          cursor: isDisabled ? "default" : "pointer",
        }}
        onClick={isDisabled ? null : onClick}
      >
        {isPlaying ? (
          <StopIcon className="w-6 h-6" />
        ) : (
          <PlayIcon className="w-6 h-6" />
        )}
      </div>
    </div>
  );
};
