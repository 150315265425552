export function imageToBase64(url) {
  return new Promise((res) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64String = reader.result;
          res(base64String);
        };
      });
  });
}

export function fileToBase64(file) {
  return new Promise((res) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      res(base64String);
    };
    reader.readAsDataURL(file);
  });
}

export const imageToCanvas = (image, width, height) => {
  // Create a canvas element
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');

  const w = width || image.width;
  const h = height || image.height;
  canvas.width = w;
  canvas.height = h;

  // ctx.imageSmoothingEnabled = false;
  // ctx.imageSmoothingQuality = 'high';
  
  // Draw the image onto the canvas
  ctx.drawImage(image, 0, 0, w, h);

  return { canvas, ctx };
};

export const imageToImageData = (image) => {
  const { canvas, ctx } = imageToCanvas(image);

  // Extract ImageData
  return ctx.getImageData(0, 0, canvas.width, canvas.height);
};

export const srcToImage = (src) => {
  return new Promise((res) => {
    // Create an image object
    var image = new Image();
    
    // Set the source of the image to the base64 string
    image.src = src;
    
    // Wait for the image to load
    image.onload = function() {
      return res(image);
    };
  });
};

export const base64ToImage = (base64) => {
  var base64String = `data:image/png;base64,${base64}`;
  return srcToImage(base64String);
};

export const base64ToImageData = async (base64) => {
  const image = await srcToImage(base64);
  return imageToImageData(image);
};

export const base64ToCanvas = async (base64) => {
  const image = await srcToImage(base64);
  return imageToCanvas(image);
};

export const canvasToImage = async (canvas) => {
  let base64 = canvas.toDataURL();
  return base64ToImage(base64);
};
