import _pica from "pica";
import { srcToImage } from "./base64";
import { config } from "./config";
import { getBuffer, getStripeLayers, useAppStore } from "./stores/useAppStore";
import { MAX_CANVAS_HEIGHT, MAX_CANVAS_WIDTH } from "./sizes";

const pica = _pica(); 

export {
  mergeTranslation,
  getTranslation,
  resetTransform,
  boundsWithBuffer,
  resizeImage,
  capImageSize,
  adjustSizeWithAspectRatio,
  onGetCanvasSize,
  onGetSize,
  getCanvasMaxSize,
  getWrapperSize,
  getFrameLength,
  cleanUrl,
  getCtx,
  getCanvas,
  getDuration,
  getRecordingLength,
};

const getCanvas = () => {
  return document.getElementById("canvas");
};

const getCtx = () => {
  const canvas = getCanvas();
  const ctx = canvas?.getContext("2d");
  return { ctx, canvas };
};

const getTranslation = ({ x1, x2, y1, y2 }) => {
  return {
    x: x2 - x1,
    y: y2 - y1,
  };
};

const mergeTranslation = (a, b) => {
  const updated = {
    x: (a.x || 0) + (b.x || 0),
    y: (a.y || 0) + (b.y || 0),
  };
  return updated;
};

const resetTransform = (ctx) => {
  const dpr = window.devicePixelRatio;

  return ctx.setTransform(dpr, 0, 0, dpr, 0, 0);
};

const boundsWithBuffer = (bounds) => {
  const buffer = getBuffer();

  return {
    ...bounds,
    left: bounds.left + buffer,
    top: bounds.top + buffer,
  };
};

const resizeImage = async (src, width, height) => {
  // Create an Image object
  const img = await srcToImage(src);
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  await pica.resize(img, canvas);

  return canvas.toDataURL();
}

const capImageSize = async (src, maxWidth, maxHeight) => {
  const img = await srcToImage(src);
  if (img.width <= maxWidth && img.height <= maxHeight) {
    return src;
  }
  const sX = maxWidth / img.width;
  const sY = maxHeight / img.height;
  const scale = Math.min(sX, sY);
  return resizeImage(src, img.width * scale, img.height * scale);
};

function adjustSizeWithAspectRatio(originalWidth, originalHeight, minWidth, minHeight, maxWidth, maxHeight) {
  // Calculate the original aspect ratio
  const aspectRatio = originalWidth / originalHeight;

  // Adjust width and height based on the aspect ratio
  let newWidth = originalWidth;
  let newHeight = originalHeight;

  if (originalWidth < minWidth) {
    newWidth = minWidth;
    newHeight = newWidth / aspectRatio;
  }

  if (newWidth > maxWidth) {
    newWidth = maxWidth;
    newHeight = newWidth / aspectRatio;
  }

  if (newHeight < minHeight) {
    newHeight = minHeight;
    newWidth = newHeight * aspectRatio;
  }
  if (newHeight > maxHeight) {
    newHeight = maxHeight;
    newWidth = newHeight * aspectRatio;
  }

  return { width: newWidth, height: newHeight };
}

const getCanvasSize = (imgSize, buffer, aspectRatio) => {
  // Set the "actual" size of the canvas
  const padding = buffer * 2;
  let minWidth = imgSize.width + padding;
  let minHeight = imgSize.height + padding;
  
  const maxSize = getCanvasMaxSize(aspectRatio);
  
  const width = minWidth;
  const height = width / aspectRatio;

  return adjustSizeWithAspectRatio(width, height, minWidth, minHeight, maxSize.width, maxSize.height);
};

const onGetCanvasSize = (imgSize) => {
  const buffer = getBuffer();

  const imgRatio = getImageRatio(imgSize);

  return getCanvasSize(imgSize, buffer, imgRatio);
};

// returns the largest size the canvas could be
const getCanvasMaxSize = (aspectRatio) => {
  let width = MAX_CANVAS_WIDTH;
  let height = width / aspectRatio;

  if (height > MAX_CANVAS_HEIGHT) {
    height = MAX_CANVAS_HEIGHT;
    width = height * aspectRatio;
  }

  return { width, height };
};

const getImageRatio = (imgSize) => {
  const aspectRatio = useAppStore.getState().aspectRatio;
  const buffer = getBuffer();

  if (aspectRatio) {
    return aspectRatio;
  }

  const ratio = imgSize.width / imgSize.height;

  let width = 0;
  let height = 0;

  const padding = buffer * 2;

  if (imgSize.width > imgSize.height) {
    width = MAX_CANVAS_WIDTH - padding;
    height = width / ratio;
  } else {
    height = MAX_CANVAS_HEIGHT - padding;
    width = height * ratio;
  }

  return (width + padding) / (height + padding);
};

const getSize = (imgSize, aspectRatio) => {
  const padding = getBuffer() * 2;

  let width = imgSize.width;
  let height = imgSize.height;

  const maxSize = getCanvasMaxSize(aspectRatio);
  const maxHeight = maxSize.height - padding;
  const maxWidth = maxSize.width - padding;
  
  return adjustSizeWithAspectRatio(width, height, 0, 0, maxWidth, maxHeight);
};

const onGetSize = (imgSize) => {
  const imgRatio = getImageRatio(imgSize);

  return getSize(imgSize, imgRatio);
};

const WRAPPER_PADDING = 50;

const getWrapperSize = () => {
  return {
    width: MAX_CANVAS_WIDTH + WRAPPER_PADDING,
    height: MAX_CANVAS_HEIGHT + WRAPPER_PADDING,
  };
};

const getSnapType = () => {
  return getStripeLayers()?.length ? "stripe" : "basic";
};

const getDuration = () => {
  // if its stripe we'll hard code to 3 seconds otherwise 5
  return getSnapType() === "stripe" ? 3 : 5;
}

const getFrameLength = () => {
  return config.fps * getDuration()
};

// this is the length we'll record for so the videos hit hte minimum length threshold for gcp transcoder api
const getRecordingLength = () => {
  return config.fps * 6;
};

const cleanUrl = () => {
  const url = new URL(document.location);
  // navigate to fresh url?
  window.history.replaceState(null, null, `${url.origin}${url.pathname}`)
};