export { downloadVideo, downloadImage, chunksToVideoURL, chunksToBlob, downloadVideoFromStorage, chunksToFormattedBlob }
import fixDuration from "fix-webm-duration"

const downloadImage = (canvas) => {
  var imageURL = canvas.toDataURL();
  var link = document.createElement('a');
  link.href = imageURL;
  link.download = `snapmate.png`;
  link.click();
};

const downloadVideo = (videoURL, filename) => {
  const a = document.createElement("a");
  a.href = videoURL;
  a.download = filename;
  a.click();
}

const downloadVideoFromStorage = async (url, filename) => {
  // fetch in the video
  const videoResponse = await fetch(url);
  const videoBlob = await videoResponse.blob();

  // Create a temporary URL for the video blob
  const blobUrl = URL.createObjectURL(videoBlob);

  downloadVideo(blobUrl, filename);
  URL.revokeObjectURL(blobUrl);
}

const chunksToBlob = (chunks) => {
  return new Blob(chunks, { 'type' : 'video/webm' });
}

const chunksToFormattedBlob = async (chunks, duration) => {
  const blob = chunksToBlob(chunks);
  // add the duration to the webm video file, not sure why the browser doesn't do this natively
  return fixDuration(blob, Math.round(duration), { logger: false })
}

// need to pull this out because we may not want to actually do this?
const chunksToVideoURL = async (chunks) => {
  const blob = chunksToBlob(chunks)
  return URL.createObjectURL(blob);
};
