import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'
import Picker, { Emoji, EmojiStyle } from 'emoji-picker-react'

export const EmojiPicker = ({ onEmojiClick }) => {
  let [referenceElement, setReferenceElement] = useState()
  let [popperElement, setPopperElement] = useState()
  let { styles, attributes } = usePopper(referenceElement, popperElement)

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button ref={setReferenceElement}>
            <div
              className='hover:scale-125 transition-all'
            >
              <Emoji
                unified='1f604'
                size={40}
                emojiStyle={EmojiStyle.NATIVE}
              />
            </div>
          </Popover.Button>

          <Transition
            as={Fragment}
            // show={open}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <Picker
                theme='dark'
                onEmojiClick={onEmojiClick}
                emojiStyle={EmojiStyle.NATIVE}
              />
            </Popover.Panel>
        </Transition>
        </>
      )}
    </Popover>
  )
}