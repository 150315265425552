export const int = (number, max) => {
  return `${number}`.padStart(max, " ");
};

export const float = (number, max) => {
  return `${number}`.padStart(max, " ");
};

// this needs to pad the number before we do this
export const currency = (number, max) => {
  return `$${number.toString().padStart(max, " ")}`;
};

export const percent = (number, max) => {
  return `${number}%`.padStart(max + 1, " ");
};

export const clock = (number) => {
  const date = new Date(number);
  return `${date.getHours()}:${date.getMinutes()}`;
};

export default {
  number: int,
  int,
  float,
  currency,
  percent,
  clock,
};
