import { drawLine } from "../draw";
import { useAppStore } from "../stores/useAppStore";
import { boundsWithBuffer } from "../util";

export { draw };

const draw = ({ ctx, layer, layerData: graph, frame }) => {
  if (!graph?.lines?.length) {
    return;
  }

  const numFrames = layer.end - layer.start;
  const ff = Math.max(frame - layer.start, 0);

  for (const line of graph.lines) {
    const values = line?.values;
    
    if (!values?.length) {
      continue;
    }

    const points = line.simplified || values;

    if (values) {
      const f = Math.round((values.length / numFrames) * ff);
      const pts = values.slice(0, f);
      const last = values[values.length - 1];
      const bounds = boundsWithBuffer(graph.bounds);
      
      if (!useAppStore.getState().isPlaying) {
        drawLine(ctx, values, points, bounds, line.color, graph.lineWidth);
        continue;
      }

      let value = values[f] ?? last;

      // if (frame < layer.start) {
      //   value = start;
      // }

      // if (frame > layer.end) {
      //   value = last;
      // }

      // draw the entire thing for now
      drawLine(ctx, pts, points, bounds, line.color, graph.lineWidth * 6);
    }
  }
};
