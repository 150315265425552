import { PlusIcon } from "@heroicons/react/24/solid";
import { reset } from "./stores/useAppStore";

export {
  NewOptions,
};

const NewOptions = ({ isDisabled }) => {
  const onBlank = () => {
    // navigate to fresh url?
    window.location.replace("/");

    // reset the state of the canvas
    reset();
  }

  return (
    <button
      className="btn btn-neutral btn-square text-accent"
      disabled={isDisabled}
      onClick={onBlank}
    >
      <PlusIcon className="w-6 h-6" />
    </button>
  );
};
