import formatters from "./format";
import { useAppStore } from "./stores/useAppStore";
import { imageDataToImage } from "./usePreloadMedia";
import { onGetSize } from "./util";

export {
  drawRoundedImage,
  drawCenteredImage,
  drawCenteredRoundedImage,
  createRoundedRectPath,
  drawLine,
  drawText,
  scaleCanvas,
  getImageData,
  putImageData,
};

function drawRoundedImage(ctx, img, x, y, width, height, borderRadius, shadow, fillStyle = "#fff") {
  if (shadow) {
    ctx.save(); // Save the current state
    ctx.shadowColor = shadow.color;
    ctx.shadowBlur = shadow.blur;
    ctx.shadowOffsetX = shadow.offsetX;
    ctx.shadowOffsetY = shadow.offsetY;
    ctx.fillStyle = fillStyle

    // Create the same rounded rectangle path for the shadow
    createRoundedRectPath(ctx, x, y, width, height, borderRadius);
    ctx.fill(); // Fill the path to create the shadow
  }
  
  ctx.clip(); // Clip to the rounded rectangle path
  if (img) {
    ctx.drawImage(img, x, y, width, height); // Draw the image
  }

  ctx.restore(); // Restore to the state before shadow settings
}

const drawCenteredImage = (ctx, img) => {
  const imgSize = useAppStore.getState().size; // the actual size of the image
  const canvasSize = useAppStore.getState().canvasSize; // the actual size of the image
  const size = onGetSize(imgSize);

  const x = (canvasSize.width / 2) - (size.width / 2);
  const y = (canvasSize.height / 2) - (size.height / 2);

  // purely for drawing stripe images ontop of the already rounded paths
  const offset = 6

  ctx.drawImage(img, x + offset, y + offset, size.width - (offset * 2), size.height - (offset * 2))
}

const drawCenteredRoundedImage = (ctx, img) => {
  const radius = useAppStore.getState().radius;
  const shadow = useAppStore.getState().shadow;
  const imgSize = useAppStore.getState().size; // the actual size of the image
  const canvasSize = useAppStore.getState().canvasSize; // the actual size of the image
  const size = onGetSize(imgSize);

  const x = (canvasSize.width / 2) - (size.width / 2);
  const y = (canvasSize.height / 2) - (size.height / 2);

  drawRoundedImage(ctx, img, x, y, size.width, size.height, radius, {
    color: 'rgba(0, 0, 0, 0.5)',
    blur: shadow,
  });
};

function createRoundedRectPath(ctx, x, y, width, height, borderRadius) {
  ctx.beginPath();
  ctx.moveTo(x + borderRadius, y);
  ctx.lineTo(x + width - borderRadius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
  ctx.lineTo(x + width, y + height - borderRadius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - borderRadius, y + height);
  ctx.lineTo(x + borderRadius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
  ctx.lineTo(x, y + borderRadius);
  ctx.quadraticCurveTo(x, y, x + borderRadius, y);
  ctx.closePath();
}

/**
 * Draws a line to the canvas given the context passedd in
 */
const drawLine = (ctx, raw, simplified, bounds, color, lineWidth) => {
  // filter out any null or empty values
  const points = (raw || []).filter((s) => s);

  if (!points?.length) {
    return;
  }

  // Start the path
  ctx.beginPath();

  const start = points[0];
  const last = points[points.length - 1];
  const sPoints = simplified.filter((s) => s[0] <= last[0]);

  // const bounds = getSelectionWithBuffer();
  const toX = (x) => Math.round(x + bounds.left);
  const toY = (y) => Math.round(y + bounds.top);

  // Move to the first point
  ctx.moveTo(toX(start[0]), toY(start[1]));

  // Loop through the points and draw lines between them
  for (let i = 1; i < sPoints.length; i++) {
    const x = toX(sPoints[i][0]);
    const y = toY(sPoints[i][1]);
    ctx.lineTo(x, y);
  }

  const lsp = sPoints[sPoints.length - 1];
  if (lsp[0] < last[0]) {
    ctx.lineTo(toX(last[0]), toY(last[1]));
  }

  // Style the polyline
  ctx.lineCap = "round";
  ctx.lineJoin = "round";
  ctx.strokeStyle = color; // Line color
  ctx.lineWidth = lineWidth; // Line width

  // Render the polyline
  ctx.stroke();
};

/**
 * Draws some text to a canvas given the context passed in
 */
const drawText = (ctx, text, current, bounds) => {
  const { format = "number", color, max, font } = text;
  const fontSize = `${bounds.height * (1 / .75)}px`;

  ctx.font = `${font?.weight || 400} ${fontSize} ${font?.family || `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif`}`;

  const formatter = formatters[format];
  if (!formatter) {
    return;
  }

  const formatted = formatter(current, max.toString().length);

  const chars = formatted.split("");
  const per = bounds.width / chars.length;
  // console.log("color", color, bounds);
  ctx.fillStyle = color || "black";

  const x = Math.round(bounds.left);
  const y = Math.round(bounds.top + bounds.height);

  chars.map((char, i) => {
    ctx.fillText(char, x + (i * per), y);
  });
};

const scaleCanvas = (canvas, ctx, width, height) => {
  // Get the DPR and size of the canvas
  const dpr = window.devicePixelRatio;

  canvas.width = width * dpr;
  canvas.height = height * dpr;

  // Scale the context to ensure correct drawing operations
  ctx.scale(dpr, dpr);

  // Set the "drawn" size of the canvas
  canvas.style.width = `${width}px`;
  canvas.style.height = `${height}px`;
};

/**
 * Helper to handle dpr stuff 
 */
const getImageData = (ctx, x, y, width, height, dpr = window.devicePixelRatio) => {
  // Get the DPR and size of the canvas
  return ctx.getImageData(x * dpr, y * dpr, width * dpr, height * dpr);
};

const putImageData = async (ctx, data, sx, sy) => {
  const image = await imageDataToImage(data);
  ctx.drawImage(image, sx, sy);
};
