import { useEffect, useRef } from "react";
import { getCanvas } from "./util";
import { nanoid } from "nanoid";
import { useAppStore } from "./stores/useAppStore";

export { useRecorder, transcode }

const baseUrl = "https://snapvibe-415123.uc.r.appspot.com"
// const baseUrl = "http://localhost:3000"

async function uploadVideoToGCS(blob, signedUrl) {
  try {
      const response = await fetch(signedUrl, {
          method: 'PUT',
          headers: {
              'Content-Type': 'video/webm' // Adjust the content type as per your video format
          },
          body: blob
      });

      if (!response.ok) {
          throw new Error('Failed to upload video to Google Cloud Storage');
      }

      console.log('Video uploaded successfully');
      return response
  } catch (error) {
      console.error('Error uploading video:', error);
  }
}

const signUrl = async (url, action) => {
  const res = await fetch(`${baseUrl}/get-signed-url`, {
    body: JSON.stringify({ filename: url, action }),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    }
  })

  return res.json()
}

const signWebm = async (id) => signUrl(`${id}.webm`, "write");
const signMp4 = async (id) => signUrl(`output/${id}.mp4`, "read");

const createTranscodingJob = async (id, endTimeOffset) => {
  const res = await fetch(`${baseUrl}/transcoder`, {
    body: JSON.stringify({ filename: id, endTimeOffset }), // no extension on this? should probably chagne
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    }
  })

  return res.json()
}

const checkStatus = async (jobId) => {
  const res = await fetch(`${baseUrl}/job-status?jobId=${jobId}`)

  return res.json()
}

const waitTilJobComplete = async (jobId) => {
  for (let i = 0; i <= 15; i++) {
    const res = await checkStatus(jobId);

    if (res.state === "SUCCEEDED") {
      return true;
    }

    if (res.state === "RUNNING") {
      // wait a second before trying again
      await new Promise((res) => setTimeout(res, 2500));
    } else {
      return false;
    }
  }
}

const transcode = async (blob, endTimeOffset) => {
  const id = nanoid();

  useAppStore.setState({ status: "uploading" });

  const { url } = await signWebm(id)
  const { url: mp4Url } = await signMp4(id)

  // upload file to gcp
  await uploadVideoToGCS(blob, url)

  useAppStore.setState({ status: "transcoding" });

  const res = await createTranscodingJob(id, endTimeOffset)

  const jobId = res.job.split("/").slice(-1)[0]

  const isSuccess = await waitTilJobComplete(jobId)
  if (!isSuccess) {
    throw new Error(`Failed to generate mp4`)
  }

  return mp4Url;
}

const useRecorder = () => {
  const recorder = useRef(null);
  const stream = useRef(null);
  let end = useRef(0)

  useEffect(() => {
    const canvas = getCanvas();
    stream.current = canvas.captureStream(60);
  
    const mimeType = MediaRecorder.isTypeSupported('video/webm;codecs=vp9')
      ? 'video/webm;codecs=vp9'
      : 'video/webm;codecs=vp8'

    const options = {
      mimeType,
      // videoBitsPerSecond: 2500000, // Use a high bitrate for better quality (e.g., 10 Mbps) // last decentone
      videoBitsPerSecond: 1000000, // Use a high bitrate for better quality (e.g., 10 Mbps)
      // frameRate: 60, // Set a higher frame rate for smoother motion
      // keyFrameInterval: 60, // Set a higher keyframe interval for better compression efficiency
      // You can adjust other settings like profile, level, etc., if needed
    };

    recorder.current = new MediaRecorder(stream.current, options)
  }, []);

  const record = (cb) => {
    const chunks = [];
    recorder.current.start();
    const start = performance.now()
  
    recorder.current.ondataavailable = function(e) {
      chunks.push(e.data);
    };
  
    recorder.current.onstop = function() {
      // only check duration if both values are present
      const duration = end.current !== 0 ? end.current - start : null
      cb(chunks, duration);
    };
  };

  const stop = () => {
    end.current = performance.now();
    recorder.current?.stop();
  }

  return {
    record,
    stop,
  }
};
