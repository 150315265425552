// different easing functions
export const easeOutQuint = (x) => 1 - Math.pow(1 - x, 10);
export const easeOutCubic = (x) => 1 - Math.pow(1 - x, 3);
export const linear = (x) => x;

export const easeInCubic = (x) => x * x * x;
export const easeInSquare = (x) => x * x;

export const range = (num) => Array.from(Array(num).keys());

export const getValues = (max = 500, numFrames = 100, easing = easeOutCubic) => {
  // so this splits up the numbers into an array of intervals equal within 1
  const steps = range(numFrames).map((s) => s / (numFrames - 1));
  // this shifts these numbers into the ease out quint algorithm
  const rawIntervals = steps.map((s) => easing(s));
  // convert them to values
  return rawIntervals.map((t) => t * max);
};
