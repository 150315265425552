const MAX_BUFFER = 140;
const MIN_BUFFER = 30;
const MAX_CANVAS_WIDTH = 850;
const MIN_CANVAS_WIDTH = 200;
const MAX_CANVAS_HEIGHT = 600;
const MIN_CANVAS_HEIGHT = 200;

const MAX_RADIUS = 25;
const MIN_RADIUS = 0;

const MAX_SHADOW = 60;
const MIN_SHADOW = 0;

export {
  MAX_BUFFER,
  MIN_BUFFER,
  MAX_CANVAS_WIDTH,
  MIN_CANVAS_WIDTH,
  MAX_CANVAS_HEIGHT,
  MIN_CANVAS_HEIGHT,

  MAX_RADIUS,
  MIN_RADIUS,
  MAX_SHADOW,
  MIN_SHADOW,
};
