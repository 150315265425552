import { useEffect } from "react";

export {
  Modal,
}

const Modal = ({ id, children, onClose, isOpen, isCloseable = true, width = "" }) => {
  const _onClose = () => {
    if (isCloseable) {
      document.getElementById(id).close();
  
      if (onClose) {
        onClose();
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.getElementById(id).showModal();
    } else {
      // close if isOpen isn't true anymore
      document.getElementById(id).close();
    }
  }, [id, isOpen]);

  return (
    <dialog id={id} className="modal" role="dialog">
      <div className={`modal-box ${width}`}>
        {isCloseable ? (
          <button className="btn btn-sm btn-circle btn-ghost absolute right-4 top-4" onClick={_onClose}>✕</button>
        ) : null}
        {children}
      </div>
      <label className="modal-backdrop" onClick={_onClose}>Close</label>
    </dialog>
  );
};
