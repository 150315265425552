import React from 'react';

const RangeSlider = ({ value, onChange, onComplete, onStart, min, max, step }) => {
  return (
    <div className="flex items-center">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        onMouseUp={onComplete}
        onMouseDown={onStart}
        className="range range-primary range-xs"
      />
    </div>
  );
};

export default RangeSlider;
