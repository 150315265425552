import { useAppStore } from "../stores/useAppStore";
import { onGetSize, resetTransform } from "../util";

export { draw }

const draw = ({ ctx, mode = "image" }) => {
  const imgSize = useAppStore.getState().size; // the actual size of the image
  const canvasSize = useAppStore.getState().canvasSize; // the actual size of the image
  const size = onGetSize(imgSize);

  const x = ((canvasSize.width - size.width) / 2) + size.width - 4;
  const y = ((canvasSize.height - size.height) / 2) + size.height + 10;

  ctx.font = `12px sans-serif`;
  ctx.fillStyle = "#000000"
  ctx.textAlign = "right";
  ctx.textBaseline = "top";
  ctx.fillText("Made with SnapMate", x, y);

  // console.log("drawing image", w, h, img, tX, tY, angleInRadians);
  resetTransform(ctx);
};