import { useAppStore, initialSticker, getLayer } from "./useAppStore";
import { config } from "../config";
import { nanoid } from "nanoid";
import { emojiToLottie, unicodeEmojiToImage, unicodeToEmoji } from "../gif";

const fps = config.fps;

export {
  addLayer,
  addSticker,
  scaleSticker,
  updateSticker,
  addEmoji,
  addCounter,
  addStripeLayer,
  addBaseImg,
  addGraphLine,
  addLineGraph,
  DEFAULT_STICKER_WIDTH,
};

const addLayer = (type, data, start = fps * 1, end = fps * 4) => {
  const id = nanoid();

  const timelineLayer = {
    start,
    end,
    type,
    id,
  };

  // add the layer to the store
  useAppStore.getState().addLayer(id, { ...data, id, type }, timelineLayer);
};

const addSticker = (data) => {
  return addLayer("sticker", {
    ...initialSticker,
    ...data,
  }, 0, fps * 5); // lets that sticker breathe
};

const DEFAULT_STICKER_WIDTH = 80;

const addEmoji = (code, pos, width = DEFAULT_STICKER_WIDTH) => {
  return addSticker({
    lottieUrl: emojiToLottie(code),
    emoji: unicodeToEmoji(code),
    img: {
      image: unicodeEmojiToImage(code),
      id: nanoid(),
    },
    bounds: {
      left: pos.x - (width / 2),
      top: pos.y - (width / 2),
      width,
      height: width,
    },
  });
};

const addCounter = (data) => {
  return addLayer("counter", {
    ...data,
  });
};

const addGraphLine = (data) => {
  return addLayer("line", {
    ...data,
  });
};

const addLineGraph = (data) => {
  return addLayer("linegraph", {
    ...data,
  });
};

const addBaseImg = (image, id = nanoid()) => {
  addLayer("base", {
    img: {
      image,
      id,
    },
  }, 0, fps * 5);
};

const addStripeLayer = (data) => {
  addLayer("stripe", {
    ...data,
    img: {
      image: data.image,
      id: nanoid(),
    },
  }, fps * 0.5, fps * 2);
};

const updateSticker = (id, updates) => {
  const layer = getLayer(id);
  if (!layer) {
    return;
  }

  useAppStore.getState().updateLayer(id, updates);
};

const scaleSticker = (id, scale) => {
  const layer = getLayer(id);
  if (!layer) {
    return;
  }

  const transform = {
    ...layer.transform,
    scale,
  };

  return updateSticker(id, { transform });
};
