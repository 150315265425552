import { getAnimations, getImageStrings, getImages, useAppStore } from "./stores/useAppStore";
import { imageToCanvas, srcToImage } from "./base64";
import Lottie from "lottie-web";

export {
  preloadImage,
  imageDataToImage,
  preloadAnimation,
  handlePreloadAnimations,
  handlePreloadImages,
}

const preloadImage = (url) => {
  return new Promise((res, rej) => {
    const img = new Image();
    img.onerror = rej;
    img.onload = function() {
      const result = imageToCanvas(img)
      res(result.canvas);
    };
    img.src = url;
    img.crossOrigin="anonymous";
  });
};

function imageDataToImage(imageData) {
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;

  const ctx = canvas.getContext('2d');
  ctx.putImageData(imageData, 0, 0);

  const dataUrl = canvas.toDataURL();

  return srcToImage(dataUrl);
}

const preloadAnimation = (url) => {
  if (useAppStore.getState().animations[url] !== undefined) {
    return useAppStore.getState().animations[url];
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  // TODO: make these scale with the actual scale the user gives it
  canvas.width = 512;
  canvas.height = 512;

  const animation = Lottie.loadAnimation({
    renderer: 'canvas',
    loop: true,
    autoplay: false,
    path: url, // the path to the animation json
    rendererSettings: {
      context: ctx,
      clearCanvas: true,
    }
  });

  animation.addEventListener("data_ready", () => {
    // update the values
    useAppStore.getState().setAnimation(url, { animation, canvas });
  });

  animation.addEventListener("data_failed", () => {
    // mark it as null so we'll know it didn't work
    useAppStore.getState().setAnimation(url, null);
  });
};

const handlePreloadAnimations = () => {
  const animations = getAnimations();

  for (const animation of animations) {
    preloadAnimation(animation);
  }
};

const handlePreloadImages = () => {
  const list = getImages();

  list.map(async (img, i) => {
    try {
      const image = await preloadImage(img.image, i);
      if (image) {
        useAppStore.getState().addImage(img.id, image);
      }
    } catch (e) {
      console.error(e);
    }
  });
};

handlePreloadImages();
handlePreloadAnimations();

// preload images
useAppStore.subscribe((s, p) => {
  const curr = getImageStrings(s);
  const pre = getImageStrings(p);

  // check if any of the values differ
  if (curr.length !== pre.length || curr.some((str, i) => str !== pre[i])) {
    handlePreloadImages();
  }

  const currAnimations = getAnimations(s);
  const preAnimations = getAnimations(p);

  if (currAnimations.length !== preAnimations.length || currAnimations.some((str, i) => str !== preAnimations[i])) {
    handlePreloadAnimations();
  }
});
